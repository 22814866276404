import { Box, Text } from "@chakra-ui/react"
import Link from "next/link"

import { Logo } from "../common/Logo"
import EmailIcon from "@/icons/Email"

type ContactUsType = {
  showLogo?: boolean
}

export const ContactUsComponent = ({ showLogo }: ContactUsType) => {
  return (
    <Box>
      {showLogo && (
        <Box mb={4}>
          <Logo color="snow.blue" showName={true} />
        </Box>
      )}
      <Text
        fontWeight={700}
        fontSize="inherit"
        mb={1}
        lineHeight={{ base: "16px" }}
      >
        Question or feedback?
      </Text>
      <Link
        style={{ display: "inline-flex", alignItems: "center" }}
        href="mailto:ask@snow.day"
      >
        Contact Us <EmailIcon height="16px" />
      </Link>
    </Box>
  )
}
