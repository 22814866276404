/* eslint-disable */
// @ts-nocheck
import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { useMutation, useQuery, useInfiniteQuery, UseMutationOptions, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type AvailabilityFilter = {
  range: DateRangeFilter;
};

export type CreateListInput = {
  authorIdentifier: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  learningOpportunityIdentifiers?: InputMaybe<Array<Scalars['String']>>;
  name: Scalars['String'];
};

export type DateRangeFilter = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type Deadline = {
  __typename?: 'Deadline';
  date?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  identifier: Scalars['String'];
  predicted: Scalars['Boolean'];
  rolling: Scalars['Boolean'];
  status: InformationStatus;
};

export type DeadlineFilter = {
  range: DateRangeFilter;
};

export type DistanceFilter = {
  unit: Scalars['String'];
  value: Scalars['Float'];
};

export type Eligibility = {
  __typename?: 'Eligibility';
  description?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  grades: Array<Grade>;
  identifier: Scalars['String'];
  restrictions?: Maybe<Array<Restriction>>;
};

export type ExpertReview = {
  __typename?: 'ExpertReview';
  expert: Scalars['String'];
  expertDescription: Scalars['String'];
  identifier: Scalars['String'];
  link: Scalars['String'];
  review: Scalars['String'];
  title: Scalars['String'];
  writtenAt: Scalars['DateTime'];
};

export enum FinancialAccessibility {
  A = 'A',
  AMinus = 'A_MINUS',
  APlus = 'A_PLUS',
  B = 'B',
  BMinus = 'B_MINUS',
  BPlus = 'B_PLUS',
  CPlus = 'C_PLUS',
  None = 'NONE'
}

export type FinancialAccessibilityFilter = {
  in: Array<InputMaybe<FinancialAccessibility>>;
};

export enum Gender {
  All = 'ALL',
  Female = 'FEMALE',
  Male = 'MALE'
}

export enum Grade {
  Eight = 'EIGHT',
  Eleven = 'ELEVEN',
  Nine = 'NINE',
  Ten = 'TEN',
  Twelve = 'TWELVE'
}

export type GradeFilter = {
  in: Array<Grade>;
};

export enum InformationStatus {
  NotAnnounced = 'NOT_ANNOUNCED',
  NotProvided = 'NOT_PROVIDED',
  UpToDate = 'UP_TO_DATE'
}

export type Interest = {
  __typename?: 'Interest';
  identifier: Scalars['String'];
  name: Scalars['String'];
};

export type InterestFilter = {
  in?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<InterestNameFilter>;
};

export type InterestNameFilter = {
  in: Array<Scalars['String']>;
};

export type LearningOpportunity = {
  __typename?: 'LearningOpportunity';
  additionalDetails?: Maybe<Scalars['String']>;
  applicationInfo?: Maybe<Scalars['String']>;
  applicationUrl?: Maybe<Scalars['String']>;
  collegeCredit?: Maybe<Scalars['Boolean']>;
  costs: Scalars['String'];
  deadlines: Array<Deadline>;
  description: Scalars['String'];
  eligibility?: Maybe<Eligibility>;
  /** @deprecated Moved to 'eligibility.description' */
  eligibilityDescription: Scalars['String'];
  expertReviews: Array<ExpertReview>;
  externalIdentifier: Scalars['String'];
  financialAccessibility: FinancialAccessibility;
  identifier: Scalars['String'];
  interests: Array<Interest>;
  link: Scalars['String'];
  media: Array<Media>;
  name: Scalars['String'];
  provider: Provider;
  registrationInfo?: Maybe<Scalars['String']>;
  registrationUrl?: Maybe<Scalars['String']>;
  scheduleDetails?: Maybe<Scalars['String']>;
  selective: Selective;
  sessions: Array<Session>;
  type: Scalars['String'];
};

export type LearningOpportunityFilter = {
  availability?: InputMaybe<AvailabilityFilter>;
  collegeCredit?: InputMaybe<Scalars['Boolean']>;
  deadline?: InputMaybe<DeadlineFilter>;
  financialAccessibility?: InputMaybe<FinancialAccessibilityFilter>;
  grade?: InputMaybe<GradeFilter>;
  interest?: InputMaybe<InterestFilter>;
  location?: InputMaybe<LocationFilter>;
  online?: InputMaybe<OnlineFilter>;
  provider?: InputMaybe<ProviderFilter>;
  restriction?: InputMaybe<RestrictionFilter>;
  selectivity?: InputMaybe<SelectivityFilter>;
  type?: InputMaybe<TypeFilter>;
};

export type LearningOpportunityOrdering = {
  field: Scalars['String'];
  order: Scalars['String'];
};

export type LearningOpportunityPage = {
  __typename?: 'LearningOpportunityPage';
  learningOpportunities: Array<LearningOpportunity>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export enum LearningOpportunityType {
  Competition = 'COMPETITION',
  SchoolYearProgram = 'SCHOOL_YEAR_PROGRAM',
  SummerProgram = 'SUMMER_PROGRAM'
}

export type List = {
  __typename?: 'List';
  author: User;
  authorIdentifier: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  identifier: Scalars['String'];
  learningOpportunities: Array<LearningOpportunity>;
  name: Scalars['String'];
  saved: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type Location = {
  __typename?: 'Location';
  identifier: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
};

export type LocationFilter = {
  radius: RadiusFilter;
};

export type Media = {
  __typename?: 'Media';
  identifier: Scalars['String'];
  type: MediaType;
  uri: Scalars['String'];
};

export enum MediaType {
  Image = 'IMAGE',
  Video = 'VIDEO'
}

export type Mutation = {
  __typename?: 'Mutation';
  createList: List;
  deleteList: List;
  removeFromList: Scalars['Boolean'];
  saveList: SavedList;
  saveToLists: Array<List>;
  unsaveList: SavedList;
  updateList: List;
};


export type MutationCreateListArgs = {
  input: CreateListInput;
};


export type MutationDeleteListArgs = {
  listIdentifier: Scalars['String'];
};


export type MutationRemoveFromListArgs = {
  learningOpportunityIdentifier: Scalars['String'];
  listIdentifier: Scalars['String'];
};


export type MutationSaveListArgs = {
  listIdentifier: Scalars['String'];
  userIdentifier: Scalars['String'];
};


export type MutationSaveToListsArgs = {
  input: SaveToListsInput;
};


export type MutationUnsaveListArgs = {
  listIdentifier: Scalars['String'];
  userIdentifier: Scalars['String'];
};


export type MutationUpdateListArgs = {
  description?: InputMaybe<Scalars['String']>;
  listIdentifier: Scalars['String'];
  name: Scalars['String'];
};

export type OnlineFilter = {
  includeOnline: Scalars['Boolean'];
  onlineOnly: Scalars['Boolean'];
};

export type Provider = {
  __typename?: 'Provider';
  identifier: Scalars['String'];
  name: Scalars['String'];
};

export type ProviderFilter = {
  name?: InputMaybe<ProviderNameFilter>;
};

export type ProviderNameFilter = {
  in?: InputMaybe<Array<Scalars['String']>>;
};

export type Query = {
  __typename?: 'Query';
  autoCompleteSearch: SearchResult;
  currentUser: User;
  /** @deprecated Use a search filter instead */
  getLearningOpportunitiesByNameStartingWith: Array<LearningOpportunity>;
  getProvidersByNameStartingWith: Array<Provider>;
  getSessionsByLearningOpportunityId: Array<Session>;
  interests: Array<Interest>;
  interestsAutoCompleteSearch: Array<Interest>;
  learningOpportunities: LearningOpportunityPage;
  learningOpportunity: LearningOpportunity;
  list: List;
  lists: Array<List>;
  savedLearningOpportunities: Array<Scalars['String']>;
  savedLists: Array<List>;
};


export type QueryAutoCompleteSearchArgs = {
  query?: InputMaybe<Scalars['String']>;
};


export type QueryGetLearningOpportunitiesByNameStartingWithArgs = {
  name: Scalars['String'];
};


export type QueryGetProvidersByNameStartingWithArgs = {
  name: Scalars['String'];
};


export type QueryGetSessionsByLearningOpportunityIdArgs = {
  identifier: Scalars['String'];
};


export type QueryInterestsArgs = {
  name: Scalars['String'];
};


export type QueryInterestsAutoCompleteSearchArgs = {
  query?: InputMaybe<Scalars['String']>;
};


export type QueryLearningOpportunitiesArgs = {
  filter?: InputMaybe<LearningOpportunityFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  orderings?: InputMaybe<Array<LearningOpportunityOrdering>>;
  page?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};


export type QueryLearningOpportunityArgs = {
  identifier: Scalars['String'];
};


export type QueryListArgs = {
  identifier: Scalars['String'];
};


export type QueryListsArgs = {
  authorIdentifier: Scalars['String'];
};


export type QuerySavedListsArgs = {
  saverIdentifier: Scalars['String'];
};

export type RadiusFilter = {
  distance: DistanceFilter;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export enum Restriction {
  FirstGen = 'FIRST_GEN',
  LowIncome = 'LOW_INCOME',
  UnderRepresentedMinority = 'UNDER_REPRESENTED_MINORITY',
  UsCitizen = 'US_CITIZEN',
  UsResident = 'US_RESIDENT'
}

export type RestrictionFilter = {
  in: Array<InputMaybe<Restriction>>;
};

export type SaveToListsInput = {
  authorIdentifier: Scalars['String'];
  learningOpportunityIdentifier: Scalars['String'];
  listIdentifiers?: InputMaybe<Array<Scalars['String']>>;
  newListNames?: InputMaybe<Array<Scalars['String']>>;
};

export type SavedList = {
  __typename?: 'SavedList';
  listIdentifier: Scalars['String'];
  userIdentifier: Scalars['String'];
};

export type SearchResult = {
  __typename?: 'SearchResult';
  interests: Array<Interest>;
  learningOpportunities: Array<LearningOpportunity>;
  providers: Array<Provider>;
};

export enum Selective {
  Highly = 'HIGHLY',
  None = 'NONE'
}

export type SelectivityFilter = {
  in: Array<InputMaybe<Selective>>;
};

export type Session = {
  __typename?: 'Session';
  dateType: SessionDateType;
  endDate?: Maybe<Scalars['DateTime']>;
  identifier: Scalars['String'];
  location?: Maybe<Location>;
  predicted: Scalars['Boolean'];
  startDate?: Maybe<Scalars['DateTime']>;
};

export enum SessionDateType {
  Dates = 'DATES',
  Months = 'MONTHS',
  Rolling = 'ROLLING'
}

export type TypeFilter = {
  in?: InputMaybe<Array<LearningOpportunityType>>;
};

export type User = {
  __typename?: 'User';
  identifier: Scalars['String'];
};

export type DeleteListMutationVariables = Exact<{
  listIdentifier: Scalars['String'];
}>;


export type DeleteListMutation = { __typename?: 'Mutation', deleteList: { __typename?: 'List', identifier: string } };

export type GetAutoCompleteSearchQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>;
}>;


export type GetAutoCompleteSearchQuery = { __typename?: 'Query', autoCompleteSearch: { __typename?: 'SearchResult', interests: Array<{ __typename?: 'Interest', identifier: string, name: string }>, providers: Array<{ __typename?: 'Provider', identifier: string, name: string }>, learningOpportunities: Array<{ __typename?: 'LearningOpportunity', identifier: string, name: string }> } };

export type GetInterestsAutoCompleteSearchQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>;
}>;


export type GetInterestsAutoCompleteSearchQuery = { __typename?: 'Query', interestsAutoCompleteSearch: Array<{ __typename?: 'Interest', identifier: string, name: string }> };

export type GetLearningOpportunitiesQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<LearningOpportunityFilter>;
  orderings?: InputMaybe<Array<LearningOpportunityOrdering> | LearningOpportunityOrdering>;
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetLearningOpportunitiesQuery = { __typename?: 'Query', learningOpportunities: { __typename?: 'LearningOpportunityPage', totalCount: number, pageNumber: number, pageSize: number, learningOpportunities: Array<{ __typename?: 'LearningOpportunity', identifier: string, type: string, name: string, description: string, link: string, financialAccessibility: FinancialAccessibility, selective: Selective, media: Array<{ __typename?: 'Media', identifier: string, uri: string, type: MediaType }>, provider: { __typename?: 'Provider', identifier: string, name: string }, interests: Array<{ __typename?: 'Interest', identifier: string, name: string }>, sessions: Array<{ __typename?: 'Session', identifier: string, dateType: SessionDateType, startDate?: any | null, endDate?: any | null, predicted: boolean, location?: { __typename?: 'Location', identifier: string, name: string, longitude: number, latitude: number } | null }>, deadlines: Array<{ __typename?: 'Deadline', identifier: string, description: string, date?: any | null, rolling: boolean, status: InformationStatus, predicted: boolean }> }> } };

export type GetLearningOpportunityQueryVariables = Exact<{
  identifier: Scalars['String'];
}>;


export type GetLearningOpportunityQuery = { __typename?: 'Query', learningOpportunity: { __typename?: 'LearningOpportunity', identifier: string, type: string, name: string, description: string, scheduleDetails?: string | null, additionalDetails?: string | null, costs: string, applicationInfo?: string | null, applicationUrl?: string | null, registrationInfo?: string | null, registrationUrl?: string | null, link: string, financialAccessibility: FinancialAccessibility, selective: Selective, eligibility?: { __typename?: 'Eligibility', identifier: string, description?: string | null, gender?: Gender | null, restrictions?: Array<Restriction> | null, grades: Array<Grade> } | null, media: Array<{ __typename?: 'Media', identifier: string, uri: string, type: MediaType }>, provider: { __typename?: 'Provider', identifier: string, name: string }, interests: Array<{ __typename?: 'Interest', identifier: string, name: string }>, sessions: Array<{ __typename?: 'Session', identifier: string, dateType: SessionDateType, startDate?: any | null, endDate?: any | null, predicted: boolean, location?: { __typename?: 'Location', identifier: string, name: string, latitude: number, longitude: number } | null }>, expertReviews: Array<{ __typename?: 'ExpertReview', identifier: string, review: string, title: string, writtenAt: any, expert: string, expertDescription: string, link: string }>, deadlines: Array<{ __typename?: 'Deadline', identifier: string, description: string, date?: any | null, status: InformationStatus, rolling: boolean, predicted: boolean }> } };

export type GetListQueryVariables = Exact<{
  identifier: Scalars['String'];
}>;


export type GetListQuery = { __typename?: 'Query', list: { __typename?: 'List', identifier: string, updatedAt: any, name: string, description?: string | null, saved: boolean, author: { __typename?: 'User', identifier: string }, learningOpportunities: Array<{ __typename?: 'LearningOpportunity', identifier: string, type: string, name: string, description: string, link: string, financialAccessibility: FinancialAccessibility, selective: Selective, media: Array<{ __typename?: 'Media', identifier: string, uri: string, type: MediaType }>, provider: { __typename?: 'Provider', identifier: string, name: string }, interests: Array<{ __typename?: 'Interest', identifier: string, name: string }>, sessions: Array<{ __typename?: 'Session', identifier: string, dateType: SessionDateType, startDate?: any | null, endDate?: any | null, predicted: boolean, location?: { __typename?: 'Location', identifier: string, name: string, longitude: number, latitude: number } | null }>, deadlines: Array<{ __typename?: 'Deadline', identifier: string, description: string, date?: any | null, rolling: boolean, status: InformationStatus, predicted: boolean }> }> } };

export type GetMyListsQueryVariables = Exact<{
  currentUserIdentifier: Scalars['String'];
}>;


export type GetMyListsQuery = { __typename?: 'Query', lists: Array<{ __typename?: 'List', identifier: string, name: string, description?: string | null, learningOpportunities: Array<{ __typename?: 'LearningOpportunity', identifier: string }> }> };

export type GetSavedLearningOpportunitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSavedLearningOpportunitiesQuery = { __typename?: 'Query', savedLearningOpportunities: Array<string> };

export type GetSavedListsQueryVariables = Exact<{
  currentUserIdentifier: Scalars['String'];
}>;


export type GetSavedListsQuery = { __typename?: 'Query', savedLists: Array<{ __typename?: 'List', identifier: string, name: string, description?: string | null, learningOpportunities: Array<{ __typename?: 'LearningOpportunity', identifier: string }> }> };

export type RemoveFromListMutationVariables = Exact<{
  learningOpportunityIdentifier: Scalars['String'];
  listIdentifier: Scalars['String'];
}>;


export type RemoveFromListMutation = { __typename?: 'Mutation', removeFromList: boolean };

export type SaveListMutationVariables = Exact<{
  userIdentifier: Scalars['String'];
  listIdentifier: Scalars['String'];
}>;


export type SaveListMutation = { __typename?: 'Mutation', saveList: { __typename?: 'SavedList', userIdentifier: string, listIdentifier: string } };

export type SaveToListsMutationVariables = Exact<{
  input: SaveToListsInput;
}>;


export type SaveToListsMutation = { __typename?: 'Mutation', saveToLists: Array<{ __typename?: 'List', identifier: string }> };

export type UnsaveListMutationVariables = Exact<{
  userIdentifier: Scalars['String'];
  listIdentifier: Scalars['String'];
}>;


export type UnsaveListMutation = { __typename?: 'Mutation', unsaveList: { __typename?: 'SavedList', userIdentifier: string, listIdentifier: string } };

export type UpdateListMutationVariables = Exact<{
  listIdentifier: Scalars['String'];
  name: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
}>;


export type UpdateListMutation = { __typename?: 'Mutation', updateList: { __typename?: 'List', identifier: string } };


export const DeleteListDocument = `
    mutation deleteList($listIdentifier: String!) {
  deleteList(listIdentifier: $listIdentifier) {
    identifier
  }
}
    `;
export const useDeleteListMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteListMutation, TError, DeleteListMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<DeleteListMutation, TError, DeleteListMutationVariables, TContext>(
      ['deleteList'],
      (variables?: DeleteListMutationVariables) => fetcher<DeleteListMutation, DeleteListMutationVariables>(client, DeleteListDocument, variables, headers)(),
      options
    );
useDeleteListMutation.fetcher = (client: GraphQLClient, variables: DeleteListMutationVariables, headers?: RequestInit['headers']) => fetcher<DeleteListMutation, DeleteListMutationVariables>(client, DeleteListDocument, variables, headers);
export const GetAutoCompleteSearchDocument = `
    query getAutoCompleteSearch($query: String) {
  autoCompleteSearch(query: $query) {
    interests {
      identifier
      name
    }
    providers {
      identifier
      name
    }
    learningOpportunities {
      identifier
      name
    }
  }
}
    `;
export const useGetAutoCompleteSearchQuery = <
      TData = GetAutoCompleteSearchQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetAutoCompleteSearchQueryVariables,
      options?: UseQueryOptions<GetAutoCompleteSearchQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetAutoCompleteSearchQuery, TError, TData>(
      variables === undefined ? ['getAutoCompleteSearch'] : ['getAutoCompleteSearch', variables],
      fetcher<GetAutoCompleteSearchQuery, GetAutoCompleteSearchQueryVariables>(client, GetAutoCompleteSearchDocument, variables, headers),
      options
    );

useGetAutoCompleteSearchQuery.getKey = (variables?: GetAutoCompleteSearchQueryVariables) => variables === undefined ? ['getAutoCompleteSearch'] : ['getAutoCompleteSearch', variables];
;

export const useInfiniteGetAutoCompleteSearchQuery = <
      TData = GetAutoCompleteSearchQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetAutoCompleteSearchQueryVariables,
      client: GraphQLClient,
      variables?: GetAutoCompleteSearchQueryVariables,
      options?: UseInfiniteQueryOptions<GetAutoCompleteSearchQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetAutoCompleteSearchQuery, TError, TData>(
      variables === undefined ? ['getAutoCompleteSearch.infinite'] : ['getAutoCompleteSearch.infinite', variables],
      (metaData) => fetcher<GetAutoCompleteSearchQuery, GetAutoCompleteSearchQueryVariables>(client, GetAutoCompleteSearchDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetAutoCompleteSearchQuery.getKey = (variables?: GetAutoCompleteSearchQueryVariables) => variables === undefined ? ['getAutoCompleteSearch.infinite'] : ['getAutoCompleteSearch.infinite', variables];
;

useGetAutoCompleteSearchQuery.fetcher = (client: GraphQLClient, variables?: GetAutoCompleteSearchQueryVariables, headers?: RequestInit['headers']) => fetcher<GetAutoCompleteSearchQuery, GetAutoCompleteSearchQueryVariables>(client, GetAutoCompleteSearchDocument, variables, headers);
export const GetInterestsAutoCompleteSearchDocument = `
    query getInterestsAutoCompleteSearch($query: String) {
  interestsAutoCompleteSearch(query: $query) {
    identifier
    name
  }
}
    `;
export const useGetInterestsAutoCompleteSearchQuery = <
      TData = GetInterestsAutoCompleteSearchQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetInterestsAutoCompleteSearchQueryVariables,
      options?: UseQueryOptions<GetInterestsAutoCompleteSearchQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetInterestsAutoCompleteSearchQuery, TError, TData>(
      variables === undefined ? ['getInterestsAutoCompleteSearch'] : ['getInterestsAutoCompleteSearch', variables],
      fetcher<GetInterestsAutoCompleteSearchQuery, GetInterestsAutoCompleteSearchQueryVariables>(client, GetInterestsAutoCompleteSearchDocument, variables, headers),
      options
    );

useGetInterestsAutoCompleteSearchQuery.getKey = (variables?: GetInterestsAutoCompleteSearchQueryVariables) => variables === undefined ? ['getInterestsAutoCompleteSearch'] : ['getInterestsAutoCompleteSearch', variables];
;

export const useInfiniteGetInterestsAutoCompleteSearchQuery = <
      TData = GetInterestsAutoCompleteSearchQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetInterestsAutoCompleteSearchQueryVariables,
      client: GraphQLClient,
      variables?: GetInterestsAutoCompleteSearchQueryVariables,
      options?: UseInfiniteQueryOptions<GetInterestsAutoCompleteSearchQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetInterestsAutoCompleteSearchQuery, TError, TData>(
      variables === undefined ? ['getInterestsAutoCompleteSearch.infinite'] : ['getInterestsAutoCompleteSearch.infinite', variables],
      (metaData) => fetcher<GetInterestsAutoCompleteSearchQuery, GetInterestsAutoCompleteSearchQueryVariables>(client, GetInterestsAutoCompleteSearchDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetInterestsAutoCompleteSearchQuery.getKey = (variables?: GetInterestsAutoCompleteSearchQueryVariables) => variables === undefined ? ['getInterestsAutoCompleteSearch.infinite'] : ['getInterestsAutoCompleteSearch.infinite', variables];
;

useGetInterestsAutoCompleteSearchQuery.fetcher = (client: GraphQLClient, variables?: GetInterestsAutoCompleteSearchQueryVariables, headers?: RequestInit['headers']) => fetcher<GetInterestsAutoCompleteSearchQuery, GetInterestsAutoCompleteSearchQueryVariables>(client, GetInterestsAutoCompleteSearchDocument, variables, headers);
export const GetLearningOpportunitiesDocument = `
    query getLearningOpportunities($query: String, $filter: LearningOpportunityFilter, $orderings: [LearningOpportunityOrdering!], $page: Int, $limit: Int) {
  learningOpportunities(
    query: $query
    filter: $filter
    orderings: $orderings
    page: $page
    limit: $limit
  ) {
    totalCount
    pageNumber
    pageSize
    learningOpportunities {
      identifier
      type
      name
      description
      link
      financialAccessibility
      selective
      media {
        identifier
        uri
        type
      }
      provider {
        identifier
        name
      }
      interests {
        identifier
        name
      }
      sessions {
        identifier
        dateType
        startDate
        endDate
        location {
          identifier
          name
          longitude
          latitude
        }
        predicted
      }
      deadlines {
        identifier
        description
        date
        rolling
        status
        predicted
      }
    }
  }
}
    `;
export const useGetLearningOpportunitiesQuery = <
      TData = GetLearningOpportunitiesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetLearningOpportunitiesQueryVariables,
      options?: UseQueryOptions<GetLearningOpportunitiesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetLearningOpportunitiesQuery, TError, TData>(
      variables === undefined ? ['getLearningOpportunities'] : ['getLearningOpportunities', variables],
      fetcher<GetLearningOpportunitiesQuery, GetLearningOpportunitiesQueryVariables>(client, GetLearningOpportunitiesDocument, variables, headers),
      options
    );

useGetLearningOpportunitiesQuery.getKey = (variables?: GetLearningOpportunitiesQueryVariables) => variables === undefined ? ['getLearningOpportunities'] : ['getLearningOpportunities', variables];
;

export const useInfiniteGetLearningOpportunitiesQuery = <
      TData = GetLearningOpportunitiesQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetLearningOpportunitiesQueryVariables,
      client: GraphQLClient,
      variables?: GetLearningOpportunitiesQueryVariables,
      options?: UseInfiniteQueryOptions<GetLearningOpportunitiesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetLearningOpportunitiesQuery, TError, TData>(
      variables === undefined ? ['getLearningOpportunities.infinite'] : ['getLearningOpportunities.infinite', variables],
      (metaData) => fetcher<GetLearningOpportunitiesQuery, GetLearningOpportunitiesQueryVariables>(client, GetLearningOpportunitiesDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetLearningOpportunitiesQuery.getKey = (variables?: GetLearningOpportunitiesQueryVariables) => variables === undefined ? ['getLearningOpportunities.infinite'] : ['getLearningOpportunities.infinite', variables];
;

useGetLearningOpportunitiesQuery.fetcher = (client: GraphQLClient, variables?: GetLearningOpportunitiesQueryVariables, headers?: RequestInit['headers']) => fetcher<GetLearningOpportunitiesQuery, GetLearningOpportunitiesQueryVariables>(client, GetLearningOpportunitiesDocument, variables, headers);
export const GetLearningOpportunityDocument = `
    query getLearningOpportunity($identifier: String!) {
  learningOpportunity(identifier: $identifier) {
    identifier
    type
    name
    description
    scheduleDetails
    additionalDetails
    costs
    applicationInfo
    applicationUrl
    registrationInfo
    registrationUrl
    link
    financialAccessibility
    selective
    eligibility {
      identifier
      description
      gender
      restrictions
      grades
    }
    media {
      identifier
      uri
      type
    }
    provider {
      identifier
      name
    }
    interests {
      identifier
      name
    }
    sessions {
      identifier
      dateType
      startDate
      endDate
      location {
        identifier
        name
        latitude
        longitude
      }
      predicted
    }
    expertReviews {
      identifier
      review
      title
      writtenAt
      expert
      expertDescription
      link
    }
    deadlines {
      identifier
      description
      date
      status
      rolling
      predicted
    }
  }
}
    `;
export const useGetLearningOpportunityQuery = <
      TData = GetLearningOpportunityQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetLearningOpportunityQueryVariables,
      options?: UseQueryOptions<GetLearningOpportunityQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetLearningOpportunityQuery, TError, TData>(
      ['getLearningOpportunity', variables],
      fetcher<GetLearningOpportunityQuery, GetLearningOpportunityQueryVariables>(client, GetLearningOpportunityDocument, variables, headers),
      options
    );

useGetLearningOpportunityQuery.getKey = (variables: GetLearningOpportunityQueryVariables) => ['getLearningOpportunity', variables];
;

export const useInfiniteGetLearningOpportunityQuery = <
      TData = GetLearningOpportunityQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetLearningOpportunityQueryVariables,
      client: GraphQLClient,
      variables: GetLearningOpportunityQueryVariables,
      options?: UseInfiniteQueryOptions<GetLearningOpportunityQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetLearningOpportunityQuery, TError, TData>(
      ['getLearningOpportunity.infinite', variables],
      (metaData) => fetcher<GetLearningOpportunityQuery, GetLearningOpportunityQueryVariables>(client, GetLearningOpportunityDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetLearningOpportunityQuery.getKey = (variables: GetLearningOpportunityQueryVariables) => ['getLearningOpportunity.infinite', variables];
;

useGetLearningOpportunityQuery.fetcher = (client: GraphQLClient, variables: GetLearningOpportunityQueryVariables, headers?: RequestInit['headers']) => fetcher<GetLearningOpportunityQuery, GetLearningOpportunityQueryVariables>(client, GetLearningOpportunityDocument, variables, headers);
export const GetListDocument = `
    query getList($identifier: String!) {
  list(identifier: $identifier) {
    identifier
    updatedAt
    author {
      identifier
    }
    name
    description
    saved
    learningOpportunities {
      identifier
      type
      name
      description
      link
      financialAccessibility
      selective
      media {
        identifier
        uri
        type
      }
      provider {
        identifier
        name
      }
      interests {
        identifier
        name
      }
      sessions {
        identifier
        dateType
        startDate
        endDate
        location {
          identifier
          name
          longitude
          latitude
        }
        predicted
      }
      deadlines {
        identifier
        description
        date
        rolling
        status
        predicted
      }
    }
  }
}
    `;
export const useGetListQuery = <
      TData = GetListQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetListQueryVariables,
      options?: UseQueryOptions<GetListQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetListQuery, TError, TData>(
      ['getList', variables],
      fetcher<GetListQuery, GetListQueryVariables>(client, GetListDocument, variables, headers),
      options
    );

useGetListQuery.getKey = (variables: GetListQueryVariables) => ['getList', variables];
;

export const useInfiniteGetListQuery = <
      TData = GetListQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetListQueryVariables,
      client: GraphQLClient,
      variables: GetListQueryVariables,
      options?: UseInfiniteQueryOptions<GetListQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetListQuery, TError, TData>(
      ['getList.infinite', variables],
      (metaData) => fetcher<GetListQuery, GetListQueryVariables>(client, GetListDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetListQuery.getKey = (variables: GetListQueryVariables) => ['getList.infinite', variables];
;

useGetListQuery.fetcher = (client: GraphQLClient, variables: GetListQueryVariables, headers?: RequestInit['headers']) => fetcher<GetListQuery, GetListQueryVariables>(client, GetListDocument, variables, headers);
export const GetMyListsDocument = `
    query getMyLists($currentUserIdentifier: String!) {
  lists(authorIdentifier: $currentUserIdentifier) {
    identifier
    name
    description
    learningOpportunities {
      identifier
    }
  }
}
    `;
export const useGetMyListsQuery = <
      TData = GetMyListsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetMyListsQueryVariables,
      options?: UseQueryOptions<GetMyListsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetMyListsQuery, TError, TData>(
      ['getMyLists', variables],
      fetcher<GetMyListsQuery, GetMyListsQueryVariables>(client, GetMyListsDocument, variables, headers),
      options
    );

useGetMyListsQuery.getKey = (variables: GetMyListsQueryVariables) => ['getMyLists', variables];
;

export const useInfiniteGetMyListsQuery = <
      TData = GetMyListsQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetMyListsQueryVariables,
      client: GraphQLClient,
      variables: GetMyListsQueryVariables,
      options?: UseInfiniteQueryOptions<GetMyListsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetMyListsQuery, TError, TData>(
      ['getMyLists.infinite', variables],
      (metaData) => fetcher<GetMyListsQuery, GetMyListsQueryVariables>(client, GetMyListsDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetMyListsQuery.getKey = (variables: GetMyListsQueryVariables) => ['getMyLists.infinite', variables];
;

useGetMyListsQuery.fetcher = (client: GraphQLClient, variables: GetMyListsQueryVariables, headers?: RequestInit['headers']) => fetcher<GetMyListsQuery, GetMyListsQueryVariables>(client, GetMyListsDocument, variables, headers);
export const GetSavedLearningOpportunitiesDocument = `
    query getSavedLearningOpportunities {
  savedLearningOpportunities
}
    `;
export const useGetSavedLearningOpportunitiesQuery = <
      TData = GetSavedLearningOpportunitiesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetSavedLearningOpportunitiesQueryVariables,
      options?: UseQueryOptions<GetSavedLearningOpportunitiesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetSavedLearningOpportunitiesQuery, TError, TData>(
      variables === undefined ? ['getSavedLearningOpportunities'] : ['getSavedLearningOpportunities', variables],
      fetcher<GetSavedLearningOpportunitiesQuery, GetSavedLearningOpportunitiesQueryVariables>(client, GetSavedLearningOpportunitiesDocument, variables, headers),
      options
    );

useGetSavedLearningOpportunitiesQuery.getKey = (variables?: GetSavedLearningOpportunitiesQueryVariables) => variables === undefined ? ['getSavedLearningOpportunities'] : ['getSavedLearningOpportunities', variables];
;

export const useInfiniteGetSavedLearningOpportunitiesQuery = <
      TData = GetSavedLearningOpportunitiesQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetSavedLearningOpportunitiesQueryVariables,
      client: GraphQLClient,
      variables?: GetSavedLearningOpportunitiesQueryVariables,
      options?: UseInfiniteQueryOptions<GetSavedLearningOpportunitiesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetSavedLearningOpportunitiesQuery, TError, TData>(
      variables === undefined ? ['getSavedLearningOpportunities.infinite'] : ['getSavedLearningOpportunities.infinite', variables],
      (metaData) => fetcher<GetSavedLearningOpportunitiesQuery, GetSavedLearningOpportunitiesQueryVariables>(client, GetSavedLearningOpportunitiesDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetSavedLearningOpportunitiesQuery.getKey = (variables?: GetSavedLearningOpportunitiesQueryVariables) => variables === undefined ? ['getSavedLearningOpportunities.infinite'] : ['getSavedLearningOpportunities.infinite', variables];
;

useGetSavedLearningOpportunitiesQuery.fetcher = (client: GraphQLClient, variables?: GetSavedLearningOpportunitiesQueryVariables, headers?: RequestInit['headers']) => fetcher<GetSavedLearningOpportunitiesQuery, GetSavedLearningOpportunitiesQueryVariables>(client, GetSavedLearningOpportunitiesDocument, variables, headers);
export const GetSavedListsDocument = `
    query getSavedLists($currentUserIdentifier: String!) {
  savedLists(saverIdentifier: $currentUserIdentifier) {
    identifier
    name
    description
    learningOpportunities {
      identifier
    }
  }
}
    `;
export const useGetSavedListsQuery = <
      TData = GetSavedListsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetSavedListsQueryVariables,
      options?: UseQueryOptions<GetSavedListsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetSavedListsQuery, TError, TData>(
      ['getSavedLists', variables],
      fetcher<GetSavedListsQuery, GetSavedListsQueryVariables>(client, GetSavedListsDocument, variables, headers),
      options
    );

useGetSavedListsQuery.getKey = (variables: GetSavedListsQueryVariables) => ['getSavedLists', variables];
;

export const useInfiniteGetSavedListsQuery = <
      TData = GetSavedListsQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetSavedListsQueryVariables,
      client: GraphQLClient,
      variables: GetSavedListsQueryVariables,
      options?: UseInfiniteQueryOptions<GetSavedListsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetSavedListsQuery, TError, TData>(
      ['getSavedLists.infinite', variables],
      (metaData) => fetcher<GetSavedListsQuery, GetSavedListsQueryVariables>(client, GetSavedListsDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetSavedListsQuery.getKey = (variables: GetSavedListsQueryVariables) => ['getSavedLists.infinite', variables];
;

useGetSavedListsQuery.fetcher = (client: GraphQLClient, variables: GetSavedListsQueryVariables, headers?: RequestInit['headers']) => fetcher<GetSavedListsQuery, GetSavedListsQueryVariables>(client, GetSavedListsDocument, variables, headers);
export const RemoveFromListDocument = `
    mutation removeFromList($learningOpportunityIdentifier: String!, $listIdentifier: String!) {
  removeFromList(
    learningOpportunityIdentifier: $learningOpportunityIdentifier
    listIdentifier: $listIdentifier
  )
}
    `;
export const useRemoveFromListMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<RemoveFromListMutation, TError, RemoveFromListMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<RemoveFromListMutation, TError, RemoveFromListMutationVariables, TContext>(
      ['removeFromList'],
      (variables?: RemoveFromListMutationVariables) => fetcher<RemoveFromListMutation, RemoveFromListMutationVariables>(client, RemoveFromListDocument, variables, headers)(),
      options
    );
useRemoveFromListMutation.fetcher = (client: GraphQLClient, variables: RemoveFromListMutationVariables, headers?: RequestInit['headers']) => fetcher<RemoveFromListMutation, RemoveFromListMutationVariables>(client, RemoveFromListDocument, variables, headers);
export const SaveListDocument = `
    mutation saveList($userIdentifier: String!, $listIdentifier: String!) {
  saveList(userIdentifier: $userIdentifier, listIdentifier: $listIdentifier) {
    userIdentifier
    listIdentifier
  }
}
    `;
export const useSaveListMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SaveListMutation, TError, SaveListMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<SaveListMutation, TError, SaveListMutationVariables, TContext>(
      ['saveList'],
      (variables?: SaveListMutationVariables) => fetcher<SaveListMutation, SaveListMutationVariables>(client, SaveListDocument, variables, headers)(),
      options
    );
useSaveListMutation.fetcher = (client: GraphQLClient, variables: SaveListMutationVariables, headers?: RequestInit['headers']) => fetcher<SaveListMutation, SaveListMutationVariables>(client, SaveListDocument, variables, headers);
export const SaveToListsDocument = `
    mutation saveToLists($input: SaveToListsInput!) {
  saveToLists(input: $input) {
    identifier
  }
}
    `;
export const useSaveToListsMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SaveToListsMutation, TError, SaveToListsMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<SaveToListsMutation, TError, SaveToListsMutationVariables, TContext>(
      ['saveToLists'],
      (variables?: SaveToListsMutationVariables) => fetcher<SaveToListsMutation, SaveToListsMutationVariables>(client, SaveToListsDocument, variables, headers)(),
      options
    );
useSaveToListsMutation.fetcher = (client: GraphQLClient, variables: SaveToListsMutationVariables, headers?: RequestInit['headers']) => fetcher<SaveToListsMutation, SaveToListsMutationVariables>(client, SaveToListsDocument, variables, headers);
export const UnsaveListDocument = `
    mutation unsaveList($userIdentifier: String!, $listIdentifier: String!) {
  unsaveList(userIdentifier: $userIdentifier, listIdentifier: $listIdentifier) {
    userIdentifier
    listIdentifier
  }
}
    `;
export const useUnsaveListMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UnsaveListMutation, TError, UnsaveListMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UnsaveListMutation, TError, UnsaveListMutationVariables, TContext>(
      ['unsaveList'],
      (variables?: UnsaveListMutationVariables) => fetcher<UnsaveListMutation, UnsaveListMutationVariables>(client, UnsaveListDocument, variables, headers)(),
      options
    );
useUnsaveListMutation.fetcher = (client: GraphQLClient, variables: UnsaveListMutationVariables, headers?: RequestInit['headers']) => fetcher<UnsaveListMutation, UnsaveListMutationVariables>(client, UnsaveListDocument, variables, headers);
export const UpdateListDocument = `
    mutation updateList($listIdentifier: String!, $name: String!, $description: String) {
  updateList(
    listIdentifier: $listIdentifier
    name: $name
    description: $description
  ) {
    identifier
  }
}
    `;
export const useUpdateListMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateListMutation, TError, UpdateListMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UpdateListMutation, TError, UpdateListMutationVariables, TContext>(
      ['updateList'],
      (variables?: UpdateListMutationVariables) => fetcher<UpdateListMutation, UpdateListMutationVariables>(client, UpdateListDocument, variables, headers)(),
      options
    );
useUpdateListMutation.fetcher = (client: GraphQLClient, variables: UpdateListMutationVariables, headers?: RequestInit['headers']) => fetcher<UpdateListMutation, UpdateListMutationVariables>(client, UpdateListDocument, variables, headers);