import { createContext, useContext } from "react"

import { useQuerySyncedValue } from "../../utils/hooks/useQuerySyncedValue"
import { SortOrder, SortOrdering } from "../types"
import {
  parseSortOrderings,
  stringifySortOrderings,
} from "../utils/stringifySortOrderings"

export const SORT_KEY = "sort"

export const INITIAL_SORT_ORDERINGS: SortOrdering[] = [
  {
    field: "relevancy",
    order: SortOrder.Desc,
  },
  {
    field: "deadline",
    order: SortOrder.Asc,
  },
]

interface SortOrderingsContextType {
  orderings: SortOrdering[]
  onChange: (newOrderings: SortOrdering[]) => void
}

const SortOrderingsContext = createContext<
  SortOrderingsContextType | undefined
>(undefined)

const serializeSortOrderings = (orderings: SortOrdering[]) => {
  return stringifySortOrderings(orderings)
}

const deserializeSortOrderings = (param: string | undefined) => {
  if (!param) {
    return undefined
  }

  return parseSortOrderings(param)
}

export const SortOrderingsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [sortOrderings, setSortOrderings] = useQuerySyncedValue(
    SORT_KEY,
    serializeSortOrderings,
    deserializeSortOrderings,
    INITIAL_SORT_ORDERINGS,
  )

  const handleChange = (newOrderings: SortOrdering[]) => {
    setSortOrderings(newOrderings)
    serializeSortOrderings(newOrderings)
  }

  const value: SortOrderingsContextType = {
    orderings: sortOrderings,
    onChange: handleChange,
  }

  return (
    <SortOrderingsContext.Provider value={value}>
      {children}
    </SortOrderingsContext.Provider>
  )
}

export function useSortOrderings() {
  const context = useContext(SortOrderingsContext)
  if (!context) {
    throw new Error(
      "useSortOrderings must be used within a SortOrderingsContext",
    )
  }
  return context
}
