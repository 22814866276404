import { Icon, IconProps } from "@chakra-ui/react"

const CloseCircleIcon = (props: IconProps) => (
  <Icon width="17px" height="18px" viewBox="0 0 17 18" fill="none" {...props}>
    <path
      d="M8.5 1.917A7.077 7.077 0 0 0 1.417 9 7.077 7.077 0 0 0 8.5 16.084 7.077 7.077 0 0 0 15.583 9 7.077 7.077 0 0 0 8.5 1.917Zm3.541 9.626-.998.999L8.5 9.999l-2.543 2.543-.999-.999L7.501 9 4.958 6.457l.999-.998L8.5 8.002l2.543-2.543.998.998L9.5 9l2.542 2.543Z"
      fill="currentColor"
    />
  </Icon>
)

export default CloseCircleIcon
