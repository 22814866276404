import { Icon, IconProps } from "@chakra-ui/react"

const WebIcon = (props: IconProps) => (
  <Icon width="24px" height="24px" fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        d="M11.989 1C5.917 1 1 5.928 1 12s4.917 11 10.989 11C18.072 23 23 18.072 23 12S18.072 1 11.989 1Zm7.623 6.6h-3.245a17.214 17.214 0 0 0-1.518-3.916A8.833 8.833 0 0 1 19.612 7.6ZM12 3.244A15.495 15.495 0 0 1 14.101 7.6H9.899A15.495 15.495 0 0 1 12 3.244ZM3.486 14.2A9.066 9.066 0 0 1 3.2 12c0-.759.11-1.496.286-2.2h3.718A18.167 18.167 0 0 0 7.05 12c0 .748.066 1.474.154 2.2H3.486Zm.902 2.2h3.245a17.214 17.214 0 0 0 1.518 3.916A8.785 8.785 0 0 1 4.388 16.4Zm3.245-8.8H4.388a8.785 8.785 0 0 1 4.763-3.916A17.214 17.214 0 0 0 7.633 7.6ZM12 20.756A15.495 15.495 0 0 1 9.899 16.4h4.202A15.495 15.495 0 0 1 12 20.756Zm2.574-6.556H9.426c-.099-.726-.176-1.452-.176-2.2 0-.748.077-1.485.176-2.2h5.148c.099.715.176 1.452.176 2.2 0 .748-.077 1.474-.176 2.2Zm.275 6.116a17.214 17.214 0 0 0 1.518-3.916h3.245a8.833 8.833 0 0 1-4.763 3.916Zm1.947-6.116c.088-.726.154-1.452.154-2.2 0-.748-.066-1.474-.154-2.2h3.718c.176.704.286 1.441.286 2.2 0 .759-.11 1.496-.286 2.2h-3.718Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </Icon>
)

export default WebIcon
