import { Icon, IconProps } from "@chakra-ui/react"

const SearchBrowseIcon = (props: IconProps) => (
  <Icon width={44} height={44} viewBox="0 0 44 44" fill="none" {...props}>
    <path
      d="m16.416 23.936 3.745 3.745-3.645 3.645-3.745-3.745 3.645-3.645Z"
      fill="#62ABEA"
    />
    <path
      d="M17.377 32.142a1.289 1.289 0 0 0 0-1.823l-3.696-3.645a1.289 1.289 0 1 0-1.823 1.823l1.823 1.823 1.873 1.822c.504.504 1.32.504 1.823 0ZM20.974 28.488a1.289 1.289 0 0 0 0-1.823l-3.648-3.637a1.289 1.289 0 1 0-1.823 1.823l3.648 3.637c.503.503 1.32.503 1.823 0Z"
      fill="#fff"
    />
    <path
      d="m7.262 42.223 8.256-8.257a1.289 1.289 0 0 0 0-1.823l-3.66-3.646a1.29 1.29 0 0 0-1.824 0l-8.257 8.257a3.867 3.867 0 0 0 0 5.469 3.886 3.886 0 0 0 5.485 0Z"
      fill="#344372"
    />
    <path
      d="M29.176 29.004c7.831 0 14.18-6.349 14.18-14.18 0-7.831-6.349-14.18-14.18-14.18-7.831 0-14.18 6.349-14.18 14.18 0 7.831 6.349 14.18 14.18 14.18Z"
      fill="#62ABEA"
    />
    <circle cx={29.176} cy={14.824} r={11.611} fill="#fff" />
    <path
      d="M34.332 16.113a1.29 1.29 0 1 0 0-2.578 1.29 1.29 0 0 0 0 2.578ZM29.176 16.113a1.29 1.29 0 1 0 0-2.578 1.29 1.29 0 0 0 0 2.578ZM24.02 16.113a1.29 1.29 0 1 0 0-2.578 1.29 1.29 0 0 0 0 2.578Z"
      fill="#62ABEA"
    />
    <path
      d="M29.176 0c-8.174 0-14.824 6.65-14.824 14.824 0 2.608.677 5.06 1.865 7.192a1.918 1.918 0 0 0-1.17.556 1.921 1.921 0 0 0-.376 2.2l-1.07 1.07a1.925 1.925 0 0 0-2.2.376c-.34.34-.535.785-.56 1.262a1.917 1.917 0 0 0-1.263.561l-8.257 8.257A4.483 4.483 0 0 0 0 39.488c0 1.205.47 2.338 1.321 3.19A4.482 4.482 0 0 0 4.511 44c1.206 0 2.339-.47 3.191-1.322l8.257-8.256c.34-.34.536-.785.561-1.262a1.918 1.918 0 0 0 1.262-.561 1.921 1.921 0 0 0 .377-2.2l1.07-1.07a1.92 1.92 0 0 0 2.2-.377c.317-.318.51-.727.555-1.169a14.731 14.731 0 0 0 7.192 1.865C37.35 29.648 44 22.998 44 14.824S37.35 0 29.176 0ZM14.592 26.674l.911-.912 2.735 2.735-.912.911-2.734-2.734Zm.455 6.836-8.256 8.257a3.201 3.201 0 0 1-2.28.944c-.86 0-1.67-.335-2.278-.944a3.202 3.202 0 0 1-.944-2.279c0-.86.335-1.67.944-2.279l8.257-8.257a.64.64 0 0 1 .912 0l3.645 3.647a.64.64 0 0 1 0 .912Zm1.823-1.823a.645.645 0 0 1-.911 0l-3.646-3.646a.645.645 0 0 1 .912-.912l3.646 3.646a.645.645 0 0 1 0 .912Zm3.646-3.646a.64.64 0 0 1-.911 0l-3.646-3.646a.64.64 0 0 1 0-.912.643.643 0 0 1 .912 0l3.646 3.646a.645.645 0 0 1 0 .912Zm8.66.318c-7.463 0-13.535-6.072-13.535-13.535S21.712 1.29 29.176 1.29c7.463 0 13.535 6.072 13.535 13.535 0 7.464-6.072 13.535-13.535 13.535Z"
      fill="#344372"
    />
    <path
      d="M29.176 2.578c-2.81 0-5.453.926-7.644 2.679a.645.645 0 1 0 .805 1.007 10.838 10.838 0 0 1 6.839-2.397c6.042 0 10.957 4.915 10.957 10.957 0 6.042-4.916 10.957-10.957 10.957-6.042 0-10.957-4.915-10.957-10.957 0-2.514.828-4.878 2.396-6.838a.645.645 0 0 0-1.007-.805 12.114 12.114 0 0 0-2.678 7.643c0 6.753 5.493 12.246 12.246 12.246 6.752 0 12.246-5.493 12.246-12.246 0-6.752-5.494-12.246-12.246-12.246Z"
      fill="#344372"
    />
    <path
      d="M36.266 14.824a1.936 1.936 0 0 0-1.934-1.933 1.936 1.936 0 0 0-1.934 1.933c0 1.066.868 1.934 1.934 1.934a1.936 1.936 0 0 0 1.934-1.934Zm-2.578 0a.645.645 0 1 1 1.29.002.645.645 0 0 1-1.29-.002ZM31.11 14.824a1.936 1.936 0 0 0-1.934-1.933 1.936 1.936 0 0 0-1.934 1.933c0 1.066.868 1.934 1.934 1.934a1.936 1.936 0 0 0 1.933-1.934Zm-2.579 0a.645.645 0 1 1 1.29.002.645.645 0 0 1-1.29-.002ZM25.953 14.824a1.936 1.936 0 0 0-1.933-1.933 1.936 1.936 0 0 0-1.934 1.933c0 1.066.867 1.934 1.934 1.934a1.936 1.936 0 0 0 1.933-1.934Zm-2.578 0a.645.645 0 1 1 1.29.002.645.645 0 0 1-1.29-.002Z"
      fill="#344372"
    />
  </Icon>
)

export default SearchBrowseIcon
