import { Icon, IconProps } from "@chakra-ui/react"

const SnowflakeIcon = (props: IconProps) => (
  <Icon width="24px" height="24px" fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        d="M12.837 2.857A.847.847 0 0 0 12 2a.847.847 0 0 0-.837.857v1.645l-1.43-1.171a.824.824 0 0 0-1.177.134.871.871 0 0 0 .13 1.204l2.477 2.029V8.5l-1.418-.962a.558.558 0 0 0-.654.437l.326 1.5-1.484-.869-.477-3.21a.84.84 0 0 0-.954-.718.855.855 0 0 0-.702.977l.276 1.854-1.391-.823A.827.827 0 0 0 3.54 7a.868.868 0 0 0 .306 1.17l1.392.823-1.706.683a.864.864 0 0 0-.475 1.11.831.831 0 0 0 1.084.486l2.954-1.181 1.468.861-1.276.676a.582.582 0 0 0 0 .744l1.275.676-1.467.861-2.954-1.182a.831.831 0 0 0-1.084.487.864.864 0 0 0 .475 1.11l1.706.683-1.391.822A.868.868 0 0 0 3.54 17c.231.41.743.55 1.144.314l1.391-.823-.275 1.855a.855.855 0 0 0 .701.976.84.84 0 0 0 .954-.718l.477-3.21 1.483-.869-.325 1.5a.558.558 0 0 0 .654.437l1.418-.962v1.802l-2.477 2.029a.871.871 0 0 0-.13 1.204c.288.37.815.43 1.176.134l1.43-1.171v1.645c0 .473.376.857.838.857a.847.847 0 0 0 .837-.857v-1.645l1.43 1.171a.824.824 0 0 0 1.178-.134.871.871 0 0 0-.131-1.204l-2.477-2.029V15.5l1.418.962a.558.558 0 0 0 .654-.437l-.325-1.5 1.483.868.477 3.21a.84.84 0 0 0 .954.719.855.855 0 0 0 .701-.977l-.275-1.854 1.391.823c.4.236.913.096 1.144-.314a.868.868 0 0 0-.306-1.171l-1.392-.823 1.706-.682a.864.864 0 0 0 .475-1.11.831.831 0 0 0-1.084-.487l-2.954 1.182-1.467-.86 1.275-.677a.582.582 0 0 0 0-.744l-1.276-.676 1.468-.86 2.954 1.18a.831.831 0 0 0 1.084-.486.864.864 0 0 0-.475-1.11l-1.706-.682 1.392-.823c.4-.237.537-.76.306-1.17a.827.827 0 0 0-1.144-.315l-1.391.823.276-1.854a.855.855 0 0 0-.702-.976.84.84 0 0 0-.954.718l-.477 3.21-1.483.868.325-1.5a.558.558 0 0 0-.654-.437l-1.418.962V6.698l2.477-2.029a.871.871 0 0 0 .13-1.204.824.824 0 0 0-1.176-.134l-1.43 1.171V2.857Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </Icon>
)

export default SnowflakeIcon
