import { Icon, IconProps } from "@chakra-ui/react"

const DeadlineIcon = (props: IconProps) => (
  <Icon
    width={36}
    height={36}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_4010_155334)">
      <path
        d="M17.9971 30.7266C21.2925 30.7266 23.9736 28.0455 23.9736 24.75C23.9736 21.4545 21.2925 18.7734 17.9971 18.7734C14.7016 18.7734 12.0205 21.4545 12.0205 24.75C12.0205 28.0455 14.7016 30.7266 17.9971 30.7266ZM16.9424 21.5156C16.9424 20.9332 17.4146 20.4609 17.9971 20.4609C18.5796 20.4609 19.0518 20.9332 19.0518 21.5156V24.3131L20.8523 26.1135C21.2641 26.5254 21.2641 27.1931 20.8523 27.605C20.6463 27.811 20.3764 27.914 20.1065 27.914C19.8367 27.914 19.5667 27.8111 19.3608 27.6051L17.2514 25.4957C17.0536 25.2979 16.9425 25.0297 16.9425 24.7499V21.5156H16.9424Z"
        fill="#62ABEA"
      />
      <path
        d="M1.05469 35.1249C0.955467 35.1249 0.875 35.0444 0.875 34.9452V14.3753H35.125V34.9452C35.125 35.0444 35.0445 35.1249 34.9453 35.1249H1.05469ZM26.958 24.75C26.958 19.8082 22.9389 15.7891 17.997 15.7891C13.0552 15.7891 9.03611 19.8082 9.03611 24.75C9.03611 29.6918 13.0552 33.7109 17.997 33.7109C22.9389 33.7109 26.958 29.6918 26.958 24.75Z"
        stroke="#344372"
        strokeWidth={1.75}
      />
      <path
        d="M34.9453 4.14876L30.2344 4.14848V3.09407C30.2344 1.38808 28.8465 0.000183105 27.1404 0.000183105H27.0001C25.2941 0.000183105 23.9062 1.38808 23.9062 3.09407V4.14876L21.7969 4.14848V3.09407C21.7969 1.38808 20.409 0.000183105 18.7029 0.000183105H18.5626C16.8566 0.000183105 15.4687 1.38808 15.4687 3.09407V4.14876L13.3594 4.14848V3.0945C13.3594 1.3885 11.9715 0.000534668 10.2655 0.000534668H10.1251C8.41915 0.000534668 7.03118 1.3885 7.03118 3.0945V4.14876H1.05469C0.472219 4.14876 0 4.62098 0 5.20345V11.3909H36V5.20345C36 4.62098 35.5278 4.14876 34.9453 4.14876ZM9.14055 8.22689C9.14055 8.80935 8.66834 9.28157 8.08587 9.28157C7.5034 9.28157 7.03118 8.80935 7.03118 8.22689V4.1489H9.14055V8.22689ZM11.25 4.14848L9.14055 4.14876V3.0945C9.14055 2.55161 9.58226 2.10991 10.1251 2.10991H10.2655C10.8084 2.10991 11.25 2.55161 11.25 3.0945V4.14848ZM17.5781 8.22689C17.5781 8.80935 17.1058 9.28157 16.5234 9.28157C15.9409 9.28157 15.4687 8.80935 15.4687 8.22689V4.1489H17.5781V8.22689ZM19.6875 4.14848L17.5781 4.14876V3.09407C17.5781 2.55119 18.0198 2.10956 18.5626 2.10956H18.7029C19.2459 2.10956 19.6875 2.55119 19.6875 3.09407V4.14848ZM26.0156 8.22689C26.0156 8.80935 25.5433 9.28157 24.9609 9.28157C24.3784 9.28157 23.9062 8.80935 23.9062 8.22689V4.1489H26.0156V8.22689ZM28.125 4.14848L26.0156 4.14876V3.09407C26.0156 2.55119 26.4572 2.10956 27.0001 2.10956H27.1404C27.6834 2.10956 28.125 2.55119 28.125 3.09407V4.14848Z"
        fill="#344372"
      />
    </g>
    <defs>
      <clipPath id="clip0_4010_155334">
        <rect width={36} height={36} fill="white" />
      </clipPath>
    </defs>
  </Icon>
)
export default DeadlineIcon
