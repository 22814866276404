import { Icon, IconProps } from "@chakra-ui/react"

const LogoIcon = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" width="32px" height="32px" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.483 7.67c-.101-.29-.156-.602-.156-.927C13.327 5.228 14.52 4 15.99 4c1.242 0 2.286.875 2.581 2.058h3.498C22.365 4.875 23.409 4 24.65 4c1.472 0 2.665 1.228 2.665 2.743s-1.193 2.743-2.664 2.743c-1.241 0-2.284-.874-2.58-2.056h-3.5c-.296 1.182-1.339 2.056-2.58 2.056a2.61 2.61 0 0 1-1.74-.667l-5.29 5.445c.125.219.222.457.284.709h5.59c.23-.411.66-.688 1.155-.688.493 0 .925.277 1.155.688h4.925c.295-1.185 1.339-2.06 2.58-2.06 1.472 0 2.665 1.228 2.665 2.743S26.122 18.4 24.651 18.4c-.424 0-.825-.102-1.181-.284l-5.827 5.791c.22.399.345.86.345 1.351 0 1.515-1.192 2.743-2.664 2.743-1.241 0-2.284-.874-2.58-2.056h-3.5C8.95 27.126 7.907 28 6.665 28 5.193 28 4 26.772 4 25.257s1.193-2.742 2.664-2.742c1.242 0 2.286.874 2.581 2.057h3.498c.296-1.183 1.34-2.057 2.581-2.057.511 0 .989.148 1.394.404l5.74-5.705a2.767 2.767 0 0 1-.387-.87h-4.928c-.231.409-.661.684-1.153.684-.493 0-.923-.275-1.153-.684H9.244c-.296 1.182-1.339 2.055-2.58 2.055C5.193 18.399 4 17.17 4 15.656s1.193-2.743 2.664-2.743c.495 0 .958.139 1.355.38l5.464-5.623Zm10.833 19.643c-.92 0-1.665-.767-1.665-1.714s.745-1.714 1.665-1.714 1.665.767 1.665 1.714-.745 1.714-1.665 1.714ZM6.998 8.113c-.92 0-1.666-.768-1.666-1.715 0-.947.746-1.714 1.666-1.714.92 0 1.665.767 1.665 1.714s-.746 1.714-1.665 1.714Z"
      fill="#62ABEA"
    />
  </Icon>
)

export default LogoIcon
