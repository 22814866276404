import { Flex } from "@chakra-ui/react"

import BetaIcon from "@/icons/Beta"
import LogoIcon from "@/icons/Logo"
import SnowdayIcon from "@/icons/Snowday"

interface Props {
  color: string
  showName?: boolean
}

export const Logo = ({ color, showName = false }: Props) => (
  <Flex gap="1" direction="row" alignItems="center" color={color}>
    <LogoIcon />
    <SnowdayIcon
      color="snow.blue-medium"
      display={{
        base: showName ? "inline-block" : "none",
        lg: "inline-block",
      }}
      mt="3px"
    />
    <BetaIcon
      display={{
        base: showName ? "inline-block" : "none",
        lg: "inline-block",
      }}
      ml={1}
    />
  </Flex>
)
