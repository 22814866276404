import { Icon, IconProps } from "@chakra-ui/react"

const ArrowLeftIcon = (props: IconProps) => (
  <Icon width="24px" height="24px" fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        d="M20 12a1 1 0 0 0-1-1H7.83l4.88-4.88a1 1 0 0 0-1.415-1.415l-6.588 6.588a1 1 0 0 0 0 1.414l6.588 6.588a.997.997 0 0 0 1.41-1.41L7.83 13H19a1 1 0 0 0 1-1Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </Icon>
)

export default ArrowLeftIcon
