import { Icon, IconProps } from "@chakra-ui/react"

const LinkIcon = (props: IconProps) => (
  <Icon width="24px" height="24px" fill="none" {...props}>
    <g fill="currentColor">
      <path d="M10.928 14.358c-.343 0-.666-.134-.91-.376a4.933 4.933 0 0 1 0-6.968l2.575-2.574a4.893 4.893 0 0 1 3.483-1.441c1.318 0 2.555.512 3.484 1.44a4.933 4.933 0 0 1 0 6.968l-1.716 1.716a1.278 1.278 0 0 1-.91.377c-.343 0-.665-.134-.909-.376a1.278 1.278 0 0 1-.376-.91c0-.343.134-.666.376-.91l1.716-1.715a2.357 2.357 0 0 0 0-3.33 2.34 2.34 0 0 0-1.665-.689c-.63 0-1.22.245-1.664.689l-2.575 2.574a2.357 2.357 0 0 0 0 3.33 1.287 1.287 0 0 1-.909 2.195Z" />
      <path d="M7.924 21a4.892 4.892 0 0 1-3.483-1.441 4.932 4.932 0 0 1 0-6.968l1.287-1.287c.243-.243.566-.376.91-.376.343 0 .666.133.909.376.242.243.376.566.376.91 0 .343-.134.666-.376.91L6.259 14.41a2.357 2.357 0 0 0 0 3.33 2.34 2.34 0 0 0 1.665.688c.63 0 1.221-.244 1.665-.688l2.574-2.574a2.357 2.357 0 0 0 0-3.33 1.278 1.278 0 0 1-.377-.91c0-.343.135-.666.377-.909.243-.243.566-.376.91-.376.343 0 .666.133.909.376a4.932 4.932 0 0 1 0 6.968l-2.574 2.574a4.892 4.892 0 0 1-3.484 1.44Z" />
    </g>
  </Icon>
)

export default LinkIcon
