import { Box, Container, Flex, Text, VStack } from "@chakra-ui/react"

import { ContactUsComponent } from "./ContactUsComponent"
import { SiteLinksComponent } from "./SiteLinksComponent"
import { SocialLinks } from "./SocialLinks"
import { Logo } from "../common/Logo"
import { useWindowSize, breakpoints, Size } from "@/ui/hooks"

const Footer = () => {
  const size: Size = useWindowSize()

  return (
    <Box
      data-testid="footer"
      bg="snow.blue-light"
      mt={{ base: "80px", md: "120px" }}
      py={{ base: 14, md: 12 }}
      px={{ base: 4, md: 10 }}
      fontSize={{ base: "12px" }}
    >
      <Container>
        <Flex justifyContent="space-between" alignItems={{ md: "center" }}>
          {size.width && size.width < breakpoints.md ? (
            <>
              <ContactUsComponent showLogo />
              <VStack>
                <SocialLinks />
                <SiteLinksComponent showTrademark />
              </VStack>
            </>
          ) : (
            <>
              <ContactUsComponent />
              <VStack>
                <Logo color="snow.blue" showName={true} />
                <Text fontSize={{ base: "10px" }}>
                  &#169; Snowday {new Date().getFullYear()}
                </Text>
              </VStack>
              <VStack>
                <SocialLinks />
                <SiteLinksComponent />
              </VStack>
            </>
          )}
        </Flex>
      </Container>
    </Box>
  )
}

export default Footer
