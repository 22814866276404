import { Flex } from "@chakra-ui/react"
import Head from "next/head"
import { useRouter } from "next/router"

import useFeatureFlag from "@/featureFlags/hooks/useFeatureFlag"
import { InviteLinkFeatureFlags } from "@/featureFlags/types"
import { I18nTypes } from "@/types"
import CalloutBanner from "@/ui/CalloutBanner"
import { Header } from "@/ui/common/Header"
import Footer from "@/ui/footer/Footer"

interface Props {
  children: React.ReactNode
  headerTitle: keyof I18nTypes["titles"]["header-titles"]
}

export function SnowdayLayout({ children, headerTitle }: Props) {
  const copyLinkEnabled = useFeatureFlag(InviteLinkFeatureFlags.INVITE_LINK)
  const { pathname } = useRouter()

  const hideFooter = pathname.includes("/learning-paths/")
  const showCallout = pathname === "/" && copyLinkEnabled

  return (
    <Flex
      direction="column"
      className="min-h-screen"
      position={hideFooter ? "fixed" : undefined}
      width="100%"
      bg={pathname === "/" ? "snow.home-bg" : "white"}
    >
      <Head>
        <title>Snowday | Home</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      {showCallout && <CalloutBanner />}
      <Header title={headerTitle} />
      {children}
      {!hideFooter && <Footer />}
    </Flex>
  )
}

export const getLayout = (
  page: React.ReactNode,
  { headerTitle }: { headerTitle: keyof I18nTypes["titles"]["header-titles"] },
) => {
  return <SnowdayLayout headerTitle={headerTitle}>{page}</SnowdayLayout>
}
