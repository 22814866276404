import { Icon, IconProps } from "@chakra-ui/react"
import React from "react"

const CheckmarkIcon = (props: IconProps) => (
  <Icon
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_8571_2987)">
      <path
        d="M20 6L9 17L4 12"
        stroke="#257AC3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_8571_2987">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </Icon>
)

export default CheckmarkIcon
