import { Icon, IconProps } from "@chakra-ui/react"

const GearIcon = (props: IconProps) => (
  <Icon
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_8714_18921)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.744 2.25C9.82746 2.25 9.04523 2.91265 8.89454 3.81675L8.71588 4.88873C8.69573 5.00964 8.60155 5.1498 8.41925 5.23747C8.07519 5.40292 7.74581 5.5938 7.43353 5.8076C7.26705 5.92159 7.09873 5.9332 6.98382 5.89015L5.96464 5.50833C5.10633 5.18678 4.14134 5.53289 3.68305 6.32666L2.76107 7.92358C2.30279 8.71736 2.48554 9.72611 3.19317 10.3087L4.0337 11.0006C4.12832 11.0785 4.20243 11.2298 4.18733 11.4307C4.1732 11.6188 4.16602 11.8086 4.16602 12C4.16602 12.1915 4.1732 12.3814 4.18735 12.5695C4.20245 12.7704 4.12835 12.9217 4.03372 12.9996L3.19317 13.6916C2.48554 14.2741 2.30279 15.2829 2.76107 16.0767L3.68305 17.6736C4.14134 18.4674 5.10633 18.8135 5.96464 18.4919L6.98405 18.11C7.09895 18.067 7.26726 18.0786 7.43375 18.1925C7.74596 18.4063 8.07527 18.5971 8.41925 18.7625C8.60155 18.8502 8.69573 18.9904 8.71588 19.1113L8.89454 20.1832C9.04523 21.0874 9.82746 21.75 10.744 21.75H12.588C13.5046 21.75 14.2868 21.0874 14.4375 20.1832L14.6161 19.1113C14.6363 18.9904 14.7305 18.8502 14.9128 18.7625C15.2568 18.5971 15.5862 18.4062 15.8985 18.1924C16.065 18.0784 16.2333 18.0668 16.3482 18.1098L17.3674 18.4917C18.2257 18.8132 19.1907 18.4671 19.649 17.6733L20.571 16.0764C21.0292 15.2826 20.8465 14.2739 20.1389 13.6913L19.2983 12.9994C19.2037 12.9215 19.1296 12.7702 19.1447 12.5693C19.1588 12.3812 19.166 12.1914 19.166 12C19.166 11.8085 19.1588 11.6186 19.1447 11.4305C19.1296 11.2296 19.2037 11.0783 19.2983 11.0004L20.1389 10.3084C20.8465 9.72587 21.0292 8.71711 20.571 7.92334L19.649 6.32642C19.1907 5.53264 18.2257 5.18654 17.3674 5.50809L16.348 5.89C16.2331 5.93304 16.0648 5.92144 15.8983 5.80746C15.5861 5.59371 15.2568 5.40289 14.9128 5.23747C14.7305 5.1498 14.6363 5.00964 14.6161 4.88873L14.4375 3.81675C14.2868 2.91265 13.5046 2.25 12.588 2.25H10.744ZM11.6661 15.75C13.7372 15.75 15.4161 14.0711 15.4161 12C15.4161 9.92893 13.7372 8.25 11.6661 8.25C9.59507 8.25 7.91614 9.92893 7.91614 12C7.91614 14.0711 9.59507 15.75 11.6661 15.75Z"
        fill="#257AC3"
      />
    </g>
    <defs>
      <clipPath id="clip0_8714_18921">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </Icon>
)

export default GearIcon
