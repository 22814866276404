import "@fontsource/nunito/400.css"
import "@fontsource/nunito/700.css"
import "@fontsource/nunito/800.css"
import { Hydrate } from "@tanstack/react-query"
import "large-small-dynamic-viewport-units-polyfill"
import type { AppProps } from "next/app"
import Head from "next/head"
import Script from "next/script"
import { appWithTranslation } from "next-i18next"
import { useEffect } from "react"
import { hotjar } from "react-hotjar"

import { GtmId, HotjarId } from "@/config"
import { AppProviders } from "@/providers"
import { getLayout } from "@/ui/layouts/SnowdayLayout"

import "../styles/styles.css"

const App = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    if (HotjarId) {
      console.info("Hotjar initialized with site ID", HotjarId)
      hotjar.initialize({ id: HotjarId, sv: 6 })
    } else {
      console.warn("No Hotjar ID provided")
    }
  }, [])

  return (
    <AppProviders {...pageProps}>
      <Hydrate state={pageProps.dehydratedState}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
        </Head>
        {GtmId && (
          <>
            <Script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${GtmId}`}
              strategy="beforeInteractive"
            ></Script>

            <Script strategy="afterInteractive">
              {`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${GtmId}');`}
            </Script>
          </>
        )}
        {getLayout(<Component {...pageProps} />, pageProps)}
      </Hydrate>
    </AppProviders>
  )
}

export default appWithTranslation(App)
