import { Icon, IconProps } from "@chakra-ui/react"

const GraduationCapIcon = (props: IconProps) => (
  <Icon
    width={15}
    height={12}
    viewBox="0 0 15 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.8122 7.59379C3.14582 7.23014 2.33317 7.71245 2.33317 8.47159V8.85984C2.33317 9.22571 2.53298 9.56237 2.85414 9.73764L6.52081 11.7386C6.81938 11.9015 7.18029 11.9015 7.47886 11.7386L11.1455 9.73764C11.4667 9.56237 11.6665 9.22571 11.6665 8.85984V8.47159C11.6665 7.71245 10.8539 7.23014 10.1875 7.59379L7.47886 9.07192C7.18029 9.23486 6.81938 9.23485 6.52081 9.07192L3.8122 7.59379ZM7.47869 0.261192C7.18021 0.0983861 6.81946 0.0983862 6.52098 0.261192L1.27598 3.1221C0.581116 3.50112 0.581115 4.49888 1.27598 4.8779L6.52094 7.73878C6.81944 7.9016 7.18022 7.90159 7.47871 7.73875L11.5209 5.5335C12.1873 5.16996 12.9998 5.65227 12.9998 6.41136V8.66667C12.9998 9.03486 13.2983 9.33333 13.6665 9.33333C14.0347 9.33333 14.3332 9.03486 14.3332 8.66667V4.59363C14.3332 4.22769 14.1333 3.89097 13.812 3.71574L7.47869 0.261192Z"
      fill="#344372"
    />
  </Icon>
)

export default GraduationCapIcon
