import { Icon, IconProps } from "@chakra-ui/react"

const GPSPinIcon = (props: IconProps) => (
  <Icon width="24px" height="24px" fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        d="m21.432 3.063-8.25 18a.75.75 0 0 1-1.43-.25l-.697-8.368-8.367-.697a.75.75 0 0 1-.25-1.43l18-8.25a.75.75 0 0 1 .994.995Z"
        fill="currentColor"
      />
    </g>
  </Icon>
)

export default GPSPinIcon
