import { Icon, IconProps } from "@chakra-ui/react"

const VideoIcon = (props: IconProps) => (
  <Icon width="24px" height="24px" fill="none" {...props}>
    <g clipPath="url(#clip0_5604_172520)">
      <path
        d="M13.1816 6.71225H4.96937C3.88621 6.71225 3 7.59846 3 8.68162V15.3184C3 16.4015 3.88621 17.2877 4.96937 17.2877H13.1816C14.2648 17.2877 15.151 16.4015 15.151 15.3184V8.68162C15.151 7.57877 14.2648 6.71225 13.1816 6.71225Z"
        fill="currentColor"
      ></path>
      <path
        d="M19.5427 7.79539C19.4246 7.81509 19.3064 7.87417 19.208 7.93325L16.1357 9.70568V14.2746L19.2276 16.047C19.7988 16.3818 20.5077 16.1849 20.8425 15.6138C20.941 15.4365 21.0001 15.2396 21.0001 15.023V8.93762C21.0001 8.20896 20.3108 7.61815 19.5427 7.79539Z"
        fill="currentColor"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_5604_172520">
        <rect width="24" height="24" fill="#fff"></rect>
      </clipPath>
    </defs>
  </Icon>
)

export default VideoIcon
