import { Icon, IconProps } from "@chakra-ui/react"
import * as React from "react"

const WriteIcon = (props: IconProps) => (
  <Icon fill="none" viewBox="0 0 36 36" width="36px" height="36px" {...props}>
    <path
      fill="#344372"
      d="M32.25 26a4 4 0 0 1-4 4H5.5a4 4 0 0 1-4-4v-1.25h30.75V26Z"
    />
    <path
      fill="#62ABEA"
      d="M34.231 4.038 31.708.758a1 1 0 0 0-1.366-.21L12.506 13.034a.999.999 0 0 0-.227.219L8.55 18.225c-.577.769.157 1.83 1.08 1.56l5.966-1.74a1 1 0 0 0 .286-.136L34.004 5.472a1 1 0 0 0 .227-1.434Z"
    />
    <path
      fill="#344372"
      d="M3.6 30h7.8v1.8a3.004 3.004 0 0 0-3 3A1.2 1.2 0 0 0 9.6 36H24a1.2 1.2 0 0 0 1.2-1.2 3.004 3.004 0 0 0-3-3V30H30a2.4 2.4 0 0 0 2.4-2.4V10.2a.6.6 0 1 0-1.2 0V24H2.4V10.2A1.2 1.2 0 0 1 3.6 9h.6v13.2a.6.6 0 1 0 1.2 0V3a.6.6 0 0 1 .6-.6h20.817l-3.068 2.122-11.828 8.18a.58.58 0 0 0-.17.165l-3.83 5.616a1.2 1.2 0 0 0 1.273 1.843l6.606-1.602a.757.757 0 0 0 .216-.1L28.2 10.195V22.2a.6.6 0 1 0 1.2 0V9.367l4.365-3.019a2.4 2.4 0 0 0 .609-3.339l-1.365-1.974A2.404 2.404 0 0 0 29.67.426l-1.118.774H6A1.8 1.8 0 0 0 4.2 3v4.8h-.6a2.4 2.4 0 0 0-2.4 2.4v17.4A2.4 2.4 0 0 0 3.6 30ZM27.654 9.115l-2.73-3.948 1.48-1.023 2.731 3.948-1.48 1.023ZM23.94 5.85l2.73 3.948-10.859 7.508-2.73-3.948L23.94 5.85ZM12.17 18.37l-1.37-1.98 1.45-2.124 2.418 3.498-2.499.606Zm-3.258.79 1.164-1.708.844 1.22-2.008.487ZM30.353 1.412a1.2 1.2 0 0 1 1.67.305l1.364 1.973c.376.546.24 1.292-.304 1.67L30.12 7.41 27.39 3.46l2.963-2.048ZM24 34.8H9.6a1.8 1.8 0 0 1 1.8-1.8h10.8a1.8 1.8 0 0 1 1.8 1.8Zm-3-3h-8.4V30H21v1.8Zm10.2-6.6v2.4a1.2 1.2 0 0 1-1.2 1.2H3.6a1.2 1.2 0 0 1-1.2-1.2v-2.4h28.8Z"
    />
    <path
      fill="#fff"
      d="M16.2 27.6H18a.6.6 0 1 0 0-1.2h-1.8a.6.6 0 1 0 0 1.2Z"
    />
    <path
      fill="#344372"
      d="M7.8 5.4h12.384a.6.6 0 1 0 0-1.2H7.8a.6.6 0 1 0 0 1.2ZM7.8 8.4h8.046a.6.6 0 1 0 0-1.2H7.8a.6.6 0 1 0 0 1.2ZM7.8 11.4h3.708a.6.6 0 1 0 0-1.2H7.8a.6.6 0 1 0 0 1.2ZM25.8 19.2h-8.322a.6.6 0 1 0 0 1.2H25.8a.6.6 0 1 0 0-1.2ZM25.8 16.2h-3.984a.6.6 0 1 0 0 1.2H25.8a.6.6 0 1 0 0-1.2Z"
    />
  </Icon>
)

export default WriteIcon
