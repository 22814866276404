import { ReactElement } from "react"

import { AuthProvider } from "@/features/auth/providers/AuthProvider"
import { PaginationProvider } from "@/features/pagination/providers/PaginationProvider"
import { SearchProvider } from "@/features/search/providers/SearchProvider"
import { FilterProvider } from "@/filters/providers/FiltersProvider"
import { QueryClientProvider } from "@/graphql/QueryClientProvider"
import { SortOrderingsProvider } from "@/sorting/providers/SortOrderingsProvider"
import { ChakraProvider } from "@/ui/chakra/ChakraProvider"
import { ErrorBoundaryProvider } from "@/ui/errors/ErrorBoundaryProvider"
import { SwipeProvider } from "@/ui/swiper/SwipeProvider"

export type ProviderProps = {
  children: ReactElement
}

const providers = [
  AuthProvider,
  ChakraProvider,
  ErrorBoundaryProvider,
  SwipeProvider,
  QueryClientProvider,
  SearchProvider,
  FilterProvider,
  SortOrderingsProvider,
  PaginationProvider,
]

export const AppProviders = ({ children }: ProviderProps) => {
  return providers.reduce(
    (children, Provider) => <Provider>{children}</Provider>,
    children,
  )
}
