import { useBoolean, useDisclosure } from "@chakra-ui/react"
import { useRouter } from "next/router"
import {
  createContext,
  RefCallback,
  useCallback,
  useContext,
  useEffect,
} from "react"
import { SwipeEventData, useSwipeable } from "react-swipeable"

import { ProviderProps } from "@/providers"
import { useWindowSize, Size } from "@/ui/hooks"

interface SwipeProviderContextProps {
  menuDisclosure: {
    isOpen: boolean
    onOpen: () => void
    onClose: () => void
    onToggle: () => void
    isControlled: boolean
    getButtonProps: (props?: unknown) => unknown
    getDisclosureProps: (props?: unknown) => unknown
  }
  chapterDisclosure: {
    isOpen: boolean
    onOpen: () => void
    onClose: () => void
    onToggle: () => void
    isControlled: boolean
    getButtonProps: (props?: unknown) => unknown
    getDisclosureProps: (props?: unknown) => unknown
  }
  swiping: boolean
  setSwiping: {
    on: () => void
    off: () => void
    toggle: () => void
  }
  setShouldCancel: {
    on: () => void
    off: () => void
    toggle: () => void
  }
}

const warning = (disclosure: string) =>
  console.warn(`Tried to use ${disclosure} before it was ready.`)

const SwipeProviderContext = createContext<SwipeProviderContextProps>({
  menuDisclosure: {
    isOpen: false,
    onOpen: () => warning("menuDisclosure"),
    onClose: () => warning("menuDisclosure"),
    onToggle: () => warning("menuDisclosure"),
    isControlled: false,
    getButtonProps: () => warning("menuDisclosure"),
    getDisclosureProps: () => warning("menuDisclosure"),
  },
  chapterDisclosure: {
    isOpen: false,
    onOpen: () => warning("chapterDisclosure"),
    onClose: () => warning("chapterDisclosure"),
    onToggle: () => warning("chapterDisclosure"),
    isControlled: false,
    getButtonProps: () => warning("chapterDisclosure"),
    getDisclosureProps: () => warning("chapterDisclosure"),
  },
  swiping: false,
  setSwiping: {
    on: () => warning("setSwiping"),
    off: () => warning("setSwiping"),
    toggle: () => warning("setSwiping"),
  },
  setShouldCancel: {
    on: () => warning("setShouldCancel"),
    off: () => warning("setShouldCancel"),
    toggle: () => warning("setShouldCancel"),
  },
})

export const useSwipe = () => useContext(SwipeProviderContext)

export const SwipeProvider = ({ children }: ProviderProps) => {
  const [swiping, setSwiping] = useBoolean()
  const [shouldCancel, setShouldCancel] = useBoolean(false)
  const { pathname } = useRouter()
  const size: Size = useWindowSize()
  const menuDisclosure = useDisclosure()
  const chapterDisclosure = useDisclosure()

  const swipedRightHandler = useCallback(
    (eventData: SwipeEventData) => {
      if (!size.width || shouldCancel) {
        return
      }
      if (menuDisclosure.isOpen && !chapterDisclosure.isOpen) {
        menuDisclosure.onClose()
      } else if (
        !chapterDisclosure.isOpen &&
        !menuDisclosure.isOpen &&
        pathname.includes("learning-paths") &&
        eventData.initial[0] >= 0 &&
        eventData.initial[0] <= size.width * 0.15
      ) {
        chapterDisclosure.onOpen()
      }
    },
    [pathname, menuDisclosure, chapterDisclosure, shouldCancel],
  )

  const swipedLeftHandler = useCallback(
    (eventData: SwipeEventData) => {
      if (!size.width || shouldCancel) {
        return
      }
      if (
        chapterDisclosure.isOpen &&
        !menuDisclosure.isOpen &&
        pathname.includes("learning-paths")
      ) {
        chapterDisclosure.onClose()
      } else if (
        !menuDisclosure.isOpen &&
        !chapterDisclosure.isOpen &&
        eventData.initial[0] <= size.width &&
        eventData.initial[0] >= size.width * 0.85
      ) {
        menuDisclosure.onOpen()
      }
    },
    [pathname, menuDisclosure, chapterDisclosure, shouldCancel],
  )

  const { ref } = useSwipeable({
    onSwipedRight: (eventData: SwipeEventData) => swipedRightHandler(eventData),
    onSwipedLeft: (eventData: SwipeEventData) => swipedLeftHandler(eventData),
  }) as { ref: RefCallback<Document> }

  // attach swipeable to document
  useEffect(() => {
    ref(document)
  })

  return (
    <SwipeProviderContext.Provider
      value={{
        chapterDisclosure,
        menuDisclosure,
        swiping,
        setSwiping,
        setShouldCancel,
      }}
    >
      {children}
    </SwipeProviderContext.Provider>
  )
}
