import { ErrorBoundary } from "react-error-boundary"

import { ProviderProps } from "@/providers"
import { GenericError } from "@/ui/errors/GenericError"

export const ErrorBoundaryProvider = ({ children }: ProviderProps) => {
  return (
    <ErrorBoundary FallbackComponent={GenericError}>{children}</ErrorBoundary>
  )
}
