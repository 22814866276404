"use client"
import { createContext, useEffect, useState } from "react"
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react"
import EmailPassword from "supertokens-auth-react/recipe/emailpassword"
import Session, {
  useSessionContext,
} from "supertokens-auth-react/recipe/session"
import ThirdParty, { Google } from "supertokens-auth-react/recipe/thirdparty"

import { User } from "../../../generated/graphql"
import {
  SUPER_TOKENS_API_BASE_PATH,
  SUPER_TOKENS_API_DOMAIN,
  SUPER_TOKENS_API_GATEWAY_PATH,
  SUPER_TOKENS_APP_NAME,
  SUPER_TOKENS_WEBSITE_BASE_PATH,
  SUPER_TOKENS_WEBSITE_DOMAIN,
} from "@/config"

if (typeof window !== "undefined") {
  SuperTokens.init({
    appInfo: {
      appName: SUPER_TOKENS_APP_NAME,
      apiDomain: SUPER_TOKENS_API_DOMAIN,
      websiteDomain: SUPER_TOKENS_WEBSITE_DOMAIN,
      apiBasePath: SUPER_TOKENS_API_BASE_PATH,
      websiteBasePath: SUPER_TOKENS_WEBSITE_BASE_PATH,
      apiGatewayPath: SUPER_TOKENS_API_GATEWAY_PATH,
    },
    recipeList: [
      ThirdParty.init({
        signInAndUpFeature: {
          providers: [Google.init()],
        },
      }),
      EmailPassword.init(),
      Session.init(),
    ],
    getRedirectionURL: (context) => {
      if (context.action === "SUCCESS" && context.newSessionCreated) {
        if (context.redirectToPath !== undefined) {
          return Promise.resolve(context.redirectToPath)
        }
        return Promise.resolve("/account/lists")
      }
      return Promise.resolve(undefined)
    },
    style: `
      [data-supertokens~=button] {
        background-color: rgb(98, 171, 234);
        border-color: rgb(98, 171, 234);
      }

      [data-supertokens~=superTokensBranding] {
        display: none;
      }
    `,
  })
}

interface CurrentUserContextType {
  currentUser?: User
}

export const CurrentUserContext = createContext<CurrentUserContextType>({
  currentUser: undefined,
})

export const CurrentUserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const session = useSessionContext()
  const [currentUser, setCurrentUser] = useState<User>()

  useEffect(() => {
    if (session.loading || !session.doesSessionExist) {
      setCurrentUser(undefined)
      return
    }

    const { accessTokenPayload } = session
    const { identifier } = accessTokenPayload

    setCurrentUser({
      identifier,
    })
  }, [session])

  return (
    <CurrentUserContext.Provider value={{ currentUser }}>
      {children}
    </CurrentUserContext.Provider>
  )
}

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <SuperTokensWrapper>
      <CurrentUserProvider>{children}</CurrentUserProvider>
    </SuperTokensWrapper>
  )
}
