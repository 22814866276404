import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client"

import { queryClient } from "./client"
import { reactQueryPersister } from "./persister"
import { ProviderProps } from "../../providers"

export const QueryClientProvider = ({ children }: ProviderProps) => {
  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{
        persister: reactQueryPersister,
      }}
    >
      {children}
    </PersistQueryClientProvider>
  )
}
