import { HStack, Link } from "@chakra-ui/react"

import InstagramIcon from "../icons/Instagram"
import LinkedInIcon from "../icons/LinkedIn"

export const SocialLinks = () => {
  return (
    <HStack width="100%" justifyContent="flex-end">
      <Link href="https://www.instagram.com/snowday_edu/" target="_blank">
        <InstagramIcon boxSize={4} />
      </Link>
      <Link href="https://www.linkedin.com/company/85882777/" target="_blank">
        <LinkedInIcon boxSize={4} />
      </Link>
    </HStack>
  )
}
