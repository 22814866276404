import { Icon, IconProps } from "@chakra-ui/react"

const ArrowRightIcon = (props: IconProps) => (
  <Icon width="24px" height="22px" fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.116 20.884a1.25 1.25 0 0 1 0-1.768L15.232 12 8.116 4.884a1.25 1.25 0 1 1 1.768-1.768l8 8a1.25 1.25 0 0 1 0 1.768l-8 8a1.25 1.25 0 0 1-1.768 0Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </Icon>
)

export default ArrowRightIcon
