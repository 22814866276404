import { createContext, useContext } from "react"

import { usePagination } from "../../pagination/providers/PaginationProvider"
import { useQuerySyncedValue } from "../../utils/hooks/useQuerySyncedValue"

interface SearchContext {
  query?: string
  setQuery: (newQuery: string | undefined) => void
  resetQuery: () => void
}

const SearchContext = createContext<SearchContext | undefined>(undefined)

export const QUERY_KEY = "query"

const serialize = (value: string | undefined): string | undefined => {
  return value
}

const deserialize = (param: string | undefined): string | undefined => {
  return param
}

export const SearchProvider = ({ children }: { children: React.ReactNode }) => {
  const { resetPageNumber } = usePagination()

  const [query, setQuerySyncedValue] = useQuerySyncedValue<string>(
    QUERY_KEY,
    serialize,
    deserialize,
    undefined,
  )

  const setQuery = (newQuery: string | undefined) => {
    setQuerySyncedValue(newQuery)
    resetPageNumber()
  }

  const resetQuery = () => {
    setQuery(undefined)
  }

  return (
    <SearchContext.Provider value={{ query, setQuery, resetQuery }}>
      {children}
    </SearchContext.Provider>
  )
}

export function useSearch() {
  const context = useContext(SearchContext)
  if (!context) {
    throw new Error("useSearch must be used within a SearchProvider")
  }

  return context
}
