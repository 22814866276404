import { Icon, IconProps } from "@chakra-ui/react"

const AlarmIcon = (props: IconProps) => (
  <Icon
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6098 4.44892C22.0403 4.78379 22.1231 5.40191 21.7958 5.83827L21.5124 6.21617C21.1769 6.66349 20.5398 6.7488 20.0985 6.40552L17.2266 4.17184C16.796 3.83697 16.7133 3.21884 17.0405 2.78248L17.324 2.40459C17.6594 1.95727 18.2965 1.87195 18.7379 2.21523L21.6098 4.44892ZM6.95975 2.78249C7.28701 3.21884 7.20424 3.83697 6.77369 4.17184L3.90181 6.40552C3.46044 6.7488 2.82336 6.66349 2.48787 6.21617L2.20444 5.83827C1.87718 5.40191 1.95995 4.78379 2.3905 4.44892L5.26238 2.21523C5.70375 1.87195 6.34083 1.95727 6.67632 2.40459L6.95975 2.78249ZM3.09849 13.1032C3.09849 8.18754 7.08445 4.20158 12.0001 4.20158C16.9158 4.20158 20.9018 8.18754 20.9018 13.1032C20.9018 18.0189 16.9158 22.0049 12.0001 22.0049C7.07456 22.0049 3.09849 18.0189 3.09849 13.1032ZM12.0001 8.0194C12.5464 8.0194 12.9892 8.46222 12.9892 9.00847V12.5505C12.9892 12.8158 13.0946 13.0701 13.2821 13.2577L15.7439 15.7194C16.1344 16.1099 16.1344 16.7431 15.7439 17.1336L15.6745 17.203C15.2839 17.5935 14.6508 17.5935 14.2602 17.203L11.304 14.2467C11.1164 14.0592 11.0111 13.8048 11.0111 13.5396V9.00847C11.0111 8.46222 11.4539 8.0194 12.0001 8.0194Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_4065_149229">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </Icon>
)
export default AlarmIcon
