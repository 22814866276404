import { Center, Text } from "@chakra-ui/react"

const CalloutBanner = () => {
  return (
    <Center
      role={"callout-banner"}
      bg="snow.blue-dark"
      padding={2}
      fontSize={{ base: 12, lg: 16 }}
      textAlign="center"
      display="flex"
      alignItems="center"
      flexDirection={{ base: "column", lg: "row" }}
      gap={1}
    >
      <Text color="white" fontSize="inherit">
        NEW: 200+ Competitions just added!
      </Text>
      <Text color="white" fontSize="inherit">
        "Lists" functionality coming very soon.
      </Text>
    </Center>
  )
}

export default CalloutBanner
