import { Icon, IconProps } from "@chakra-ui/react"

const DropdownIcon = (props: IconProps) => (
  <Icon width="20px" height="20px" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.597 6.763a1.042 1.042 0 0 1 1.473 0l5.93 5.93 5.93-5.93a1.042 1.042 0 0 1 1.473 1.473l-6.667 6.667a1.041 1.041 0 0 1-1.473 0L2.597 8.236a1.042 1.042 0 0 1 0-1.473Z"
      fill="currentColor"
    />
  </Icon>
)

export default DropdownIcon
