import { Icon, IconProps } from "@chakra-ui/react"

const PinIcon = (props: IconProps) => (
  <Icon
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_8495_7052)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8765 8.55234L10.8675 5.45867L11.4862 5.05686C11.8265 4.83586 11.9241 4.37662 11.7032 4.03632C11.4822 3.69601 11.0229 3.5984 10.6826 3.81939L4.49528 7.83749C4.15498 8.05849 4.05736 8.51773 4.27836 8.85804C4.49935 9.19834 4.9586 9.29595 5.2989 9.07496L5.91763 8.67315L7.92668 11.7668C8.59369 12.7939 8.30301 14.1614 7.27591 14.8284L8.07953 16.0659L11.7734 13.6671L14.586 17.9982L15.6066 18.2152L15.8235 17.1946L13.0108 12.8635L16.7418 10.4406L15.9382 9.2031C14.9111 9.87011 13.5436 9.57943 12.8765 8.55234Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_8495_7052">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </Icon>
)

export default PinIcon
