import { Icon, IconProps } from "@chakra-ui/react"

const CopyIcon = (props: IconProps) => (
  <Icon width="24px" height="24px" fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        d="M16 2a1 1 0 0 0-1-1H4c-1.1 0-2 .9-2 2v13a1 1 0 1 0 2 0V3h11a1 1 0 0 0 1-1Zm3 3H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2Zm0 16H8V7h11v14Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </Icon>
)

export default CopyIcon
