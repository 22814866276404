import { Flex, Link, List, ListItem, Text } from "@chakra-ui/react"
import NextLink from "next/link"

const footerLinks = [
  {
    name: "Terms of Service",
    href: "/terms-of-service",
  },
  {
    name: "Privacy Policy",
    href: "/privacy-policy",
  },
  // {
  //   name: "Sitemap",
  //   href: "/sitemap",
  // },
]

type SiteLinksComponentType = {
  showTrademark?: boolean
}

export const SiteLinksComponent = ({
  showTrademark,
}: SiteLinksComponentType) => {
  return (
    <Flex flexDirection="column" textAlign="right">
      <List flexGrow={1}>
        {footerLinks.map((link) => (
          <ListItem key={"footerlink-" + link.href}>
            <Link href={link.href} as={NextLink}>
              {link.name}
            </Link>
          </ListItem>
        ))}
      </List>
      {showTrademark && (
        <Text fontSize={{ base: "10px" }}>
          &#169; Snowday {new Date().getFullYear()}
        </Text>
      )}
    </Flex>
  )
}
