import { Icon, IconProps } from "@chakra-ui/react"

const DownChevronIcon = (props: IconProps) => (
  <Icon
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_8725_3181)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.07709 5.41083C2.40252 5.08539 2.93016 5.08539 3.2556 5.41083L7.99968 10.1549L12.7438 5.41083C13.0692 5.08539 13.5968 5.08539 13.9223 5.41083C14.2477 5.73626 14.2477 6.2639 13.9223 6.58934L8.58893 11.9227C8.43265 12.079 8.22069 12.1667 7.99968 12.1667C7.77866 12.1667 7.5667 12.079 7.41042 11.9227L2.07709 6.58934C1.75165 6.2639 1.75165 5.73626 2.07709 5.41083Z"
        fill="#242F50"
        fillOpacity="0.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_8725_3181">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="matrix(0 1 -1 0 16 0)"
        />
      </clipPath>
    </defs>
  </Icon>
)

export default DownChevronIcon
