import {
  Avatar,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  IconProps,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react"
import NextLink from "next/link"
import { useRouter } from "next/router"
import { signOut } from "supertokens-auth-react/recipe/session"

import { useWindowSize } from "../hooks"
import { useCurrentUser } from "@/auth/hooks/useCurrentUser"
import DownChevronIcon from "@/icons/DownChevron"
import PinIcon from "@/icons/Pin"
import SearchIcon from "@/icons/Search"
import SnowdayIcons from "@/icons/SnowdayIcons"
import SnowflakeIcon from "@/icons/Snowflake"

interface Route {
  name: string
  path: string
  isActive: (currentPath: string) => boolean
  desktop?: boolean
  mobile?: boolean
  color?: string
  icon?: (props: IconProps) => JSX.Element
}

const mainRoutes: Route[] = [
  {
    name: "Home",
    path: "/",
    isActive: (currentPath: string) => {
      return currentPath === "" || currentPath === "/"
    },
    desktop: false,
    icon: (props) => <SnowflakeIcon {...props} />,
  },
  {
    name: "Find",
    path: "/search",
    isActive: (currentPath: string) => {
      return (
        currentPath.startsWith("/search") ||
        currentPath.startsWith("/learning-opportunities")
      )
    },
    icon: (props) => <SearchIcon {...props} />,
  },
  {
    name: "Lists",
    path: "/account/lists",
    isActive: (currentPath: string) => {
      return (
        currentPath.startsWith("/account/lists") ||
        currentPath.startsWith("/lists")
      )
    },
    icon: (props) => <PinIcon {...props} />,
  },
]

const SnowdayMenu = () => {
  const router = useRouter()
  const currentUser = useCurrentUser()
  const size = useWindowSize()
  const {
    isOpen: isDrawerOpen,
    onOpen: onOpenDrawer,
    onClose: onCloseDrawer,
  } = useDisclosure()

  const handleSignIn = () => {
    router.push("/auth")
  }

  const handleSignOut = async () => {
    await signOut()
    onCloseDrawer()
    router.push("/auth")
  }

  const renderUserMenu = () => {
    if (currentUser) {
      return (
        <Menu>
          <MenuButton>
            <Avatar ml={10} size="xs" bg="snow.blue" />
            <DownChevronIcon ml={1} boxSize={3} />
          </MenuButton>
          <MenuList>
            <MenuItem fontSize="sm" onClick={handleSignOut}>
              Sign Out
            </MenuItem>
          </MenuList>
        </Menu>
      )
    }

    return (
      <Button ml={10} onClick={handleSignIn} data-testid="signin-button">
        Sign In
      </Button>
    )
  }

  const renderDesktopMenu = () => {
    return (
      <>
        <Flex
          flexDirection="row"
          alignContent="center"
          data-testid="desktop-menu"
          color="snow.blue-medium"
        >
          {mainRoutes
            .filter((route) => route.desktop ?? true)
            .map((route) => {
              const color = route.isActive(router.pathname)
                ? "snow.blue"
                : "snow.blue-medium"

              return (
                <Link
                  key={"desktopMainroute-" + route.path}
                  as={NextLink}
                  href={route.path}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  whiteSpace="nowrap"
                  color={color}
                  fontWeight={700}
                  _hover={{
                    textDecoration: "none",
                    fontWeight: 700,
                    color: "snow.blue",
                  }}
                  ml={10}
                >
                  {route.icon && route.icon({ color: "inherit", mr: 1 })}
                  {route.name}
                </Link>
              )
            })}
          {renderUserMenu()}
        </Flex>
      </>
    )
  }

  const renderMobileMenu = () => {
    return (
      <>
        <IconButton
          aria-label="menu"
          variant="transparent"
          icon={<SnowdayIcons name="HamburgerMenu" boxSize={6} />}
          onClick={onOpenDrawer}
          data-testid="mobile-menu"
        />
        <Drawer onClose={onCloseDrawer} isOpen={isDrawerOpen} size="xs">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader />
            <DrawerBody>
              {mainRoutes
                .filter((route) => route.mobile ?? true)
                .map((route) => {
                  const color = route.isActive(router.pathname)
                    ? "snow.blue"
                    : "snow.blue-medium"

                  return (
                    <Link
                      key={"mobileMainroute-" + route.path}
                      as={NextLink}
                      href={route.path}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      whiteSpace="nowrap"
                      color={color}
                      fontWeight={700}
                      _hover={{
                        textDecoration: "none",
                        fontWeight: 700,
                        color: "snow.blue",
                      }}
                      mb={4}
                    >
                      {route.icon &&
                        route.icon({ color: "inherit", mr: 1, boxSize: 7 })}
                      {route.name}
                    </Link>
                  )
                })}
              {renderMobileSignInButton()}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    )
  }

  const renderMobileSignInButton = () => {
    if (currentUser) {
      return (
        <>
          <Divider mb={4} />
          <Link
            color="snow.blue-medium"
            fontWeight={700}
            _hover={{
              textDecoration: "none",
              fontWeight: 700,
              color: "snow.blue",
            }}
            onClick={handleSignOut}
          >
            Logout
          </Link>
        </>
      )
    }

    return (
      <Button onClick={handleSignIn} data-testid="signin-button">
        Sign In
      </Button>
    )
  }

  if (size.width && size.width >= 1024) {
    return renderDesktopMenu()
  }

  return renderMobileMenu()
}

export default SnowdayMenu
