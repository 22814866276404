import { useSearchParams } from "next/navigation"
import { useCallback } from "react"

export const useUpdateQueryString = () => {
  const searchParams = useSearchParams()

  return useCallback(
    (updates: Record<string, string | null | undefined>) => {
      const params = new URLSearchParams(searchParams)

      for (const [key, value] of Object.entries(updates)) {
        if (value === null || value === undefined) {
          params.delete(key)
        } else {
          params.set(key, value)
        }
      }

      return params.toString()
    },
    [searchParams],
  )
}
