import { Icon, IconProps } from "@chakra-ui/react"

const CompetitionIcon = (props: IconProps) => (
  <Icon
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.9957 2.22944C13.9941 2.14091 13.9927 2.06447 13.9927 2H11.4666C11.5154 0.918219 11.4826 0 11.4826 0H7.02738C7.01694 4.81198e-05 7.00651 0.000632214 6.99613 0.00174999C6.98575 0.000630455 6.97532 4.63557e-05 6.96488 0H2.49828C2.49828 0 2.46553 0.918219 2.51428 2H0V3H0.00503123C0.030375 3.8155 0.160656 4.90622 0.702937 6.02278C1.16287 6.96988 1.82519 7.76228 2.67156 8.378C3.37225 8.88775 4.17284 9.255 5.05297 9.47437C5.56125 10.0168 6.0625 10.3159 6.5 10.4362V11.5494C6.4375 11.8802 6.05812 13 3.41356 13H3V14H11V13H10.5882C7.48556 13 7.5 11.4583 7.5 11.4583V10.436C7.9375 10.3159 8.42906 10.0181 8.93469 9.47838C9.82122 9.2595 10.6244 8.89084 11.3295 8.37797C12.1758 7.76225 12.8377 6.96984 13.2976 6.02275C14.0376 4.49897 14.0102 3.02259 13.9957 2.22944ZM1.60194 5.58578C1.15006 4.65519 1.03181 3.71863 1.00584 3H2.58356C2.61069 3.27856 2.645 3.55316 2.68844 3.81187C2.95447 5.39653 3.32009 6.79603 4.012 8.03091C2.95456 7.49525 2.12406 6.66091 1.60194 5.58578ZM12.398 5.58578C11.8728 6.66734 11.0354 7.50519 9.969 8.04047C10.6632 6.80325 11.0257 5.40063 11.2925 3.81191C11.3359 3.55316 11.3702 3.27856 11.3974 3.00003H12.9941C12.9682 3.71863 12.8499 4.65519 12.398 5.58578Z"
      fill="#344372"
    />
  </Icon>
)

export default CompetitionIcon
