import { Icon, IconProps } from "@chakra-ui/react"

const LeftChevronIcon = (props: IconProps) => (
  <Icon
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_8514_30486)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8839 3.11612C16.372 3.60427 16.372 4.39573 15.8839 4.88388L8.76777 12L15.8839 19.1161C16.372 19.6043 16.372 20.3957 15.8839 20.8839C15.3957 21.372 14.6043 21.372 14.1161 20.8839L6.11612 12.8839C5.8817 12.6495 5.75 12.3315 5.75 12C5.75 11.6685 5.8817 11.3505 6.11612 11.1161L14.1161 3.11612C14.6043 2.62796 15.3957 2.62796 15.8839 3.11612Z"
        fill="#257AC3"
      />
    </g>
    <defs>
      <clipPath id="clip0_8514_30486">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="matrix(-1 0 0 -1 24 24)"
        />
      </clipPath>
    </defs>
  </Icon>
)

export default LeftChevronIcon
