import { useWindowSize, breakpoints, Size } from "@/ui/hooks"

export const useDevice = () => {
  const size: Size = useWindowSize()
  const isMobile = size.width && size.width < breakpoints.md
  const isTablet = size.width && size.width < breakpoints.lg
  const isDesktop = size.width && size.width >= breakpoints.lg

  return { isMobile, isTablet, isDesktop }
}
