import {
  Container,
  Flex,
  Heading,
  LinkBox,
  LinkOverlay,
} from "@chakra-ui/react"
import Link from "next/link"
import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"
import { useMemo } from "react"

import { Logo } from "./Logo"
import { breakpoints, useWindowSize } from "../hooks"
import SnowdayMenu from "../menu/SnowdayMenu"
import { I18nTypes } from "@/types"

interface Props {
  title?: keyof I18nTypes["titles"]["header-titles"]
  variant?: boolean
  onClick?: () => void
}

const variantStyle = {
  logo: "snow.orange-medium",
  title: "snow.orange-dark",
  icons: "snow.orange",
  background: "snow.orange-extra-light",
}

const normalStyle = {
  logo: "snow.blue-medium",
  title: "snow.blue-dark",
  icons: "snow.blue",
  background: "snow.blue-light",
}

export const Header = ({ title, variant, onClick }: Props) => {
  const { t } = useTranslation("titles")
  const { pathname } = useRouter()
  const { width } = useWindowSize()

  const colorScheme = useMemo(() => {
    return variant ? variantStyle : normalStyle
  }, [variant])

  const showName = useMemo(
    () => pathname === "/" || title === undefined,
    [pathname, title],
  )

  const isExtraLargeScreen = width && width > breakpoints.xl
  const isSearchResults = pathname.startsWith("/search/results")
  const isListDetail = pathname.startsWith("/lists")

  const containerWidth =
    isExtraLargeScreen && (isSearchResults || isListDetail)
      ? "120ch"
      : undefined

  return (
    <Flex backgroundColor={colorScheme.background}>
      <Container maxWidth={containerWidth}>
        <Flex
          py={{ base: 2, md: 2, lg: 3 }}
          direction="row"
          justifyContent="space-between"
          as="header"
        >
          <LinkBox style={{ display: "inline-flex", alignItems: "center" }}>
            <LinkOverlay
              as={onClick ? undefined : Link}
              onClick={onClick ? onClick : () => null}
              href={onClick ? undefined : "/"}
              cursor="pointer"
            >
              <Flex direction="row" gap="2" alignItems="center">
                <Logo color={colorScheme.logo} showName={showName} />
                {!showName && title && (
                  <Heading
                    variant="h3"
                    display={{ base: "inline-block", lg: "none" }}
                    color={colorScheme.title}
                  >
                    {t(`header-titles.${title}` as const)}
                  </Heading>
                )}
              </Flex>
            </LinkOverlay>
          </LinkBox>

          <Flex direction="row" gap="2" alignItems="center">
            <SnowdayMenu />
          </Flex>
        </Flex>
      </Container>
    </Flex>
  )
}
