import { Icon, IconProps } from "@chakra-ui/react"

const FlameIcon = (props: IconProps) => (
  <Icon width="24px" height="24px" fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        d="M19.48 12.854c-1.57-4.08-7.16-4.3-5.81-10.23.1-.44-.37-.78-.75-.55-3.63 2.14-6.24 6.43-4.05 12.05.18.46-.36.89-.75.59-1.81-1.37-2-3.34-1.84-4.75.06-.52-.62-.77-.91-.34-.68 1.04-1.37 2.72-1.37 5.25.38 5.6 5.11 7.32 6.81 7.54 2.43.31 5.06-.14 6.95-1.87 2.08-1.93 2.84-5.01 1.72-7.69ZM8 17.004c2.5 1 3.901-1 3.5-2.5-.401-1.5-2-4-.5-7 .722 3.75 4.76 4.5 4.76 7.5 0 4-4.95 5-7.76 2Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </Icon>
)

export default FlameIcon
