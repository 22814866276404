import { Icon, IconProps } from "@chakra-ui/react"

const ProgrammingIcon = (props: IconProps) => (
  <Icon
    width={36}
    height={36}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_4010_155391)">
      <path
        d="M14.2038 25.2283C14.0678 25.2283 13.9308 25.1815 13.8191 25.0863L9.97311 21.8115C9.84044 21.6984 9.76416 21.5332 9.76416 21.3588C9.76416 21.1846 9.84077 21.0195 9.97311 20.9064L13.8191 17.6315C14.0688 17.4186 14.4439 17.4488 14.6568 17.6985C14.8694 17.9486 14.8396 18.3237 14.5895 18.5363L11.2752 21.3588L14.5895 24.1812C14.8396 24.3941 14.8694 24.7693 14.6568 25.019C14.5391 25.1573 14.372 25.2283 14.2038 25.2283Z"
        fill="#344372"
      />
      <path
        d="M21.7963 25.2283C21.6281 25.2283 21.461 25.1573 21.3436 25.019C21.1306 24.7693 21.1608 24.3941 21.4106 24.1812L24.7249 21.3588L21.4106 18.5363C21.1608 18.3237 21.1306 17.9486 21.3436 17.6985C21.5562 17.4488 21.9316 17.4186 22.181 17.6315L26.027 20.9064C26.1596 21.0195 26.2359 21.1846 26.2359 21.3588C26.2359 21.5332 26.1596 21.6984 26.027 21.8115L22.181 25.0863C22.0693 25.1815 21.9323 25.2283 21.7963 25.2283Z"
        fill="#344372"
      />
      <path
        d="M16.6387 27.6846C16.5979 27.6846 16.5568 27.6803 16.5154 27.6716C16.1943 27.604 15.9887 27.2886 16.0563 26.9675L18.4533 15.5872C18.5209 15.2661 18.836 15.0605 19.1574 15.1281C19.4784 15.1958 19.6841 15.5109 19.6164 15.8323L17.2195 27.2126C17.1604 27.4925 16.9137 27.6846 16.6387 27.6846Z"
        fill="#344372"
      />
      <path
        d="M0.818359 6.4541C0.818359 4.24496 2.60922 2.4541 4.81836 2.4541H31.182C33.3911 2.4541 35.182 4.24496 35.182 6.4541V11.4541H0.818359V6.4541Z"
        fill="#62ABEA"
      />
      <path
        d="M32.0094 33.8847H3.99057C1.79032 33.8847 0 32.0947 0 29.8941V6.11322C0 3.91297 1.79032 2.12265 3.99057 2.12265H32.0094C34.2097 2.12265 36 3.91297 36 6.11322V29.8941C36 32.0947 34.2097 33.8847 32.0094 33.8847ZM3.99057 3.31133C2.44568 3.31133 1.18868 4.56833 1.18868 6.11322V29.8941C1.18868 31.439 2.44568 32.696 3.99057 32.696H32.0094C33.5543 32.696 34.8113 31.439 34.8113 29.8941V6.11322C34.8113 4.56833 33.5543 3.31133 32.0094 3.31133H3.99057Z"
        fill="#344372"
      />
      <path
        d="M35.4057 11.6397H0.59434C0.265994 11.6397 0 11.3737 0 11.0454C0 10.7173 0.265994 10.451 0.59434 10.451H35.4057C35.734 10.451 36 10.7173 36 11.0454C36 11.3737 35.734 11.6397 35.4057 11.6397Z"
        fill="#344372"
      />
      <circle cx={15.9336} cy={6.89648} r={1} fill="white" />
      <circle cx={10.1548} cy={6.89648} r={1} fill="white" />
      <circle cx={4.37598} cy={6.89648} r={1} fill="white" />
      <path
        d="M10.1548 8.79569C9.11801 8.79569 8.27393 7.9516 8.27393 6.91483C8.27393 5.87772 9.11801 5.03397 10.1548 5.03397C11.1919 5.03397 12.0356 5.87772 12.0356 6.91483C12.0356 7.9516 11.1919 8.79569 10.1548 8.79569ZM10.1548 6.22265C9.77337 6.22265 9.46261 6.53308 9.46261 6.91483C9.46261 7.29657 9.77337 7.60701 10.1548 7.60701C10.5365 7.60701 10.847 7.29657 10.847 6.91483C10.847 6.53308 10.5365 6.22265 10.1548 6.22265Z"
        fill="#344372"
      />
      <path
        d="M4.37598 8.77747C3.33887 8.77747 2.49512 7.93372 2.49512 6.89661C2.49512 5.8595 3.33887 5.01575 4.37598 5.01575C5.41309 5.01575 6.25684 5.8595 6.25684 6.89661C6.25684 7.93372 5.41309 8.77747 4.37598 8.77747ZM4.37598 6.20443C3.99456 6.20443 3.6838 6.51519 3.6838 6.89661C3.6838 7.27835 3.99456 7.58879 4.37598 7.58879C4.75772 7.58879 5.06816 7.27835 5.06816 6.89661C5.06816 6.51519 4.75772 6.20443 4.37598 6.20443Z"
        fill="#344372"
      />
      <path
        d="M15.9338 8.8136C14.897 8.8136 14.0532 7.96985 14.0532 6.93274C14.0532 5.89597 14.897 5.05222 15.9338 5.05222C16.9709 5.05222 17.8146 5.89597 17.8146 6.93274C17.8146 7.96985 16.9709 8.8136 15.9338 8.8136ZM15.9338 6.24056C15.5523 6.24056 15.2419 6.551 15.2419 6.93274C15.2419 7.31449 15.5523 7.62492 15.9338 7.62492C16.3155 7.62492 16.6259 7.31449 16.6259 6.93274C16.6259 6.551 16.3155 6.24056 15.9338 6.24056Z"
        fill="#344372"
      />
    </g>
    <defs>
      <clipPath id="clip0_4010_155391">
        <rect width={36} height={36} fill="white" />
      </clipPath>
    </defs>
  </Icon>
)
export default ProgrammingIcon
