import { ChakraProvider as CP, CSSReset } from "@chakra-ui/react"

import defaultTheme from "./theme/theme"
import { ProviderProps } from "@/providers"

export const ChakraProvider = ({ children }: ProviderProps) => {
  return (
    <CP theme={defaultTheme}>
      <CSSReset />
      {children}
    </CP>
  )
}
