import { IconProps } from "@chakra-ui/react"

import AccessibleIcon from "./Accessible"
import AlarmIcon from "./Alarm"
import ApplyIcon from "./Apply"
import ArrowLeftIcon from "./ArrowLeft"
import ArrowRightIcon from "./ArrowRight"
import AskIcon from "./Ask"
import BusinessIcon from "./Business"
import CalendarIcon from "./Calendar"
import ChatIcon from "./Chat"
import CheckmarkIcon from "./Checkmark"
import CirclePlusIcon from "./CirclePlus"
import CloseCircleIcon from "./CloseCircle"
import CoinIcon from "./Coin"
import CommunityIcon from "./Community"
import CommunitySmallIcon from "./CommunitySmall"
import CompetitionIcon from "./Competition"
import CompetitionsTopSearchIcon from "./CompetitionsTopSearch"
import CopyIcon from "./Copy"
import DeadlineIcon from "./Deadline"
import DropdownIcon from "./Dropdown"
import EarthIcon from "./Earth"
import EngineeringIcon from "./Engineering"
import FlagIcon from "./Flag"
import FlameIcon from "./Flame"
import FlowerPotIcon from "./FlowerPot"
import GardenIcon from "./Garden"
import GearIcon from "./Gear"
import GPSPinIcon from "./GPSPin"
import GraduationCapIcon from "./GraduationCap"
import HamburgerMenuIcon from "./HamburgerMenu"
import HomeIcon from "./Home"
import InstagramIcon from "./Instagram"
import LeadershipIcon from "./Leadership"
import LeftChevronIcon from "./LeftChevron"
import LinkIcon from "./Link"
import LinkedInIcon from "./LinkedIn"
import LocationIcon from "./Location"
import MenuIcon from "./Menu"
import OneOnOneIcon from "./OneOnOne"
import PinIcon from "./Pin"
import PlayIcon from "./Play"
import PredictedIcon from "./Predicted"
import ProgrammingIcon from "./Programming"
import QuestionMarkIcon from "./QuestionMark"
import ResearchIcon from "./Research"
import ReviewIcon from "./Review"
import RightChevronIcon from "./RightChevron"
import SchoolIcon from "./School"
import SchoolYearIcon from "./SchoolYear"
import SearchIcon from "./Search"
import SearchBrowseIcon from "./SearchBrowse"
import SelectionMedalIcon from "./SelectionMedal"
import ShareIcon from "./Share"
import SnowIcon from "./Snow"
import SnowflakeIcon from "./Snowflake"
import SocialCareIcon from "./SocialCare"
import SortIcon from "./Sort"
import SparkleIcon from "./Sparkle"
import StarIcon from "./Star"
import STEMIcon from "./STEM"
import SummerIcon from "./Summer"
import SunIcon from "./Sun"
import TrashCanIcon from "./TrashCan"
import TrophyIcon from "./Trophy"
import VideoIcon from "./Video"
import WebIcon from "./Web"
import WriteIcon from "./Write"

export const iconTypes = {
  Accessible: AccessibleIcon,
  Alarm: AlarmIcon,
  Apply: ApplyIcon,
  ArrowLeft: ArrowLeftIcon,
  ArrowRight: ArrowRightIcon,
  Ask: AskIcon,
  Business: BusinessIcon,
  Calendar: CalendarIcon,
  Chat: ChatIcon,
  Checkmark: CheckmarkIcon,
  CirclePlus: CirclePlusIcon,
  CloseCircle: CloseCircleIcon,
  Coin: CoinIcon,
  Community: CommunityIcon,
  CommunitySmall: CommunitySmallIcon,
  Competition: CompetitionIcon,
  CompetitionsTopSearch: CompetitionsTopSearchIcon,
  Copy: CopyIcon,
  Deadline: DeadlineIcon,
  Dropdown: DropdownIcon,
  Earth: EarthIcon,
  Engineering: EngineeringIcon,
  Flame: FlameIcon,
  Flag: FlagIcon,
  FlowerPot: FlowerPotIcon,
  Garden: GardenIcon,
  Gear: GearIcon,
  GpsPin: GPSPinIcon,
  GraduationCap: GraduationCapIcon,
  HamburgerMenu: HamburgerMenuIcon,
  Home: HomeIcon,
  Instagram: InstagramIcon,
  Leadership: LeadershipIcon,
  LeftChevron: LeftChevronIcon,
  Link: LinkIcon,
  LinkedIn: LinkedInIcon,
  Location: LocationIcon,
  Menu: MenuIcon,
  OneOnOne: OneOnOneIcon,
  Pin: PinIcon,
  Play: PlayIcon,
  Predicted: PredictedIcon,
  Programming: ProgrammingIcon,
  QuestionMark: QuestionMarkIcon,
  Research: ResearchIcon,
  Review: ReviewIcon,
  RightChevron: RightChevronIcon,
  School: SchoolIcon,
  SchoolYear: SchoolYearIcon,
  Search: SearchIcon,
  Selection: SelectionMedalIcon,
  SearchBrowse: SearchBrowseIcon,
  Share: ShareIcon,
  Snowflake: SnowflakeIcon,
  Snow: SnowIcon,
  Sparkle: SparkleIcon,
  SocialCare: SocialCareIcon,
  Sort: SortIcon,
  Star: StarIcon,
  STEM: STEMIcon,
  Summer: SummerIcon,
  Sun: SunIcon,
  TrashCan: TrashCanIcon,
  Trophy: TrophyIcon,
  Video: VideoIcon,
  Web: WebIcon,
  Write: WriteIcon,
}

interface SnowdayIconsProps extends IconProps {
  name: keyof typeof iconTypes
}

export default function SnowdayIcons({ name, ...props }: SnowdayIconsProps) {
  const IconComponent = iconTypes[name]
  return <IconComponent {...props} color={props.color ?? "snow.blue-medium"} />
}
