interface ErrorProps {
  error: Error
}

export function GenericError({ error }: ErrorProps) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </div>
  )
}
