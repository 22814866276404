import { Icon, IconProps } from "@chakra-ui/react"

const LinkedInIcon = (props: IconProps) => (
  <Icon
    width={24}
    height={25}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.4705 2.5H3.53055C3.34013 2.49736 3.15105 2.53225 2.97411 2.60269C2.79718 2.67312 2.63585 2.77772 2.49934 2.91051C2.36284 3.04331 2.25383 3.20169 2.17854 3.37661C2.10325 3.55154 2.06316 3.73958 2.06055 3.93V21.07C2.06316 21.2604 2.10325 21.4485 2.17854 21.6234C2.25383 21.7983 2.36284 21.9567 2.49934 22.0895C2.63585 22.2223 2.79718 22.3269 2.97411 22.3973C3.15105 22.4678 3.34013 22.5026 3.53055 22.5H20.4705C20.661 22.5026 20.85 22.4678 21.027 22.3973C21.2039 22.3269 21.3652 22.2223 21.5017 22.0895C21.6383 21.9567 21.7473 21.7983 21.8226 21.6234C21.8978 21.4485 21.9379 21.2604 21.9405 21.07V3.93C21.9379 3.73958 21.8978 3.55154 21.8226 3.37661C21.7473 3.20169 21.6383 3.04331 21.5017 2.91051C21.3652 2.77772 21.2039 2.67312 21.027 2.60269C20.85 2.53225 20.661 2.49736 20.4705 2.5ZM8.09055 19.24H5.09055V10.24H8.09055V19.24ZM6.59055 8.98C6.17681 8.98 5.78002 8.81565 5.48746 8.52309C5.1949 8.23053 5.03055 7.83374 5.03055 7.42C5.03055 7.00626 5.1949 6.60947 5.48746 6.31692C5.78002 6.02436 6.17681 5.86 6.59055 5.86C6.81024 5.83509 7.03272 5.85686 7.24342 5.92389C7.45412 5.99092 7.64829 6.10169 7.8132 6.24896C7.97812 6.39624 8.11007 6.57668 8.20042 6.77848C8.29076 6.98029 8.33746 7.1989 8.33746 7.42C8.33746 7.64111 8.29076 7.85972 8.20042 8.06152C8.11007 8.26332 7.97812 8.44377 7.8132 8.59104C7.64829 8.73831 7.45412 8.84909 7.24342 8.91612C7.03272 8.98315 6.81024 9.00492 6.59055 8.98ZM18.9105 19.24H15.9105V14.41C15.9105 13.2 15.4805 12.41 14.3905 12.41C14.0532 12.4125 13.7247 12.5183 13.4494 12.7132C13.174 12.9081 12.965 13.1827 12.8505 13.5C12.7723 13.735 12.7384 13.9826 12.7505 14.23V19.23H9.75055C9.75055 19.23 9.75055 11.05 9.75055 10.23H12.7505V11.5C13.0231 11.0271 13.4195 10.6375 13.897 10.3732C14.3745 10.1089 14.9151 9.97985 15.4605 10C17.4605 10 18.9105 11.29 18.9105 14.06V19.24Z"
      fill="#344372"
    />
  </Icon>
)

export default LinkedInIcon
